// App.js
import React, { useEffect } from 'react';
import axios from 'axios';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import {
  ListGroup,
  Container,
  FormControl,
  Spinner
} from 'react-bootstrap';
import Cookies from 'js-cookie';

// Librairie GA4
import ReactGA from 'react-ga4';

// Import des icônes
import { BiSearch } from "react-icons/bi";
// import { GiQuickSlash } from "react-icons/gi"; // <-- Supprimé car on ne l'utilise plus

// Import de Helmet pour gérer les <title>, <meta>, etc.
import { Helmet } from 'react-helmet';

// Importation des composants
import BusLines from './BusLines';
import Schedule from './Schedule';
import Header from './Header';
import Footer from './Footer';
import Arrets from './Arrets';
import StopDepartures from './StopDepartures';
import Infotrafic from './Infotrafic';
import Mentions from './Mentions';
import PrivacyPolicy from './PrivacyPolicy';
import ScrollToTop from './ScrollToTop';
import AlertDetails from './AlertDetails';

/* ------------------------------------------------------------------
   Pensez à remplacer 'G-XXXXXXXXXX' par votre identifiant GA4
   ------------------------------------------------------------------ */
ReactGA.initialize('G-3XW783VZ82');

const App = () => {
  const [networks, setNetworks] = React.useState([]);
  const [selectedNetwork, setSelectedNetwork] = React.useState(Cookies.get('selectedNetwork') || '');
  const [loading, setLoading] = React.useState(true);
  const [showTutorial, setShowTutorial] = React.useState(false);

  const location = useLocation();

  // -------------------------------
  // Intégration du script Crisp
  // -------------------------------
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "62a5f298-a7f0-4e29-8106-72cd4eea2440"; 
    (function() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);
  // Fin intégration Crisp

  // Enregistre une pageview à chaque changement de route
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  // Récupération des réseaux
  useEffect(() => {
    axios.get('/getDepartments.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setNetworks(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => console.error('Error fetching networks:', error))
      .finally(() => setLoading(false));
  }, []);

  // Afficher le tuto si c'est la première sélection d'un réseau
  useEffect(() => {
    if (selectedNetwork && !Cookies.get('tutorialShown')) {
      setShowTutorial(true);
    }
  }, [selectedNetwork]);

  // On masque le Header sur la page /schedule
  const hideHeader = location.pathname.startsWith('/schedule');

  // -----------------------
  // ECRAN DE CHARGEMENT
  // -----------------------
  if (loading) {
    // ICI : Écran de chargement plein écran, fond blanc, spinner #0a78a4
    return (
      <div 
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#fff' // fond blanc
        }}
      >
        <Spinner
          animation="border"
          role="status"
          style={{ color: '#0a78a4' }} // couleur spinner
        >
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      {!hideHeader && (
        <Header
          selectedNetwork={selectedNetwork}
          networks={networks}
          showTutorial={showTutorial}
          setShowTutorial={setShowTutorial}
        />
      )}
      
      <Routes>
        {/* 
          Si selectedNetwork est déjà défini, on redirige immédiatement vers la page du réseau.
          Sinon, on affiche Home.
        */}
        <Route
          path="/"
          element={
            selectedNetwork
              ? <Navigate to={`/network/${selectedNetwork}`} replace />
              : <Home 
                  networks={networks}
                  selectedNetwork={selectedNetwork}
                  setSelectedNetwork={setSelectedNetwork}
                />
          }
        />

        <Route path="/network/:networkId" element={<BusLines />} />
        <Route path="/schedule/:networkId/:routeId/:directionId" element={<Schedule />} />
         {/* La page de détail d’une alerte */}
         <Route path="/network/:networkId/alert/:alertId" element={<AlertDetails />} />

{/* Autres routes éventuelles, ex. /schedule, etc. */}
{/* <Route path="/schedule/:networkId/:routeId/:directionId" element={<Schedule />} /> */}
        <Route
          path="/change-network"
          element={
            <ChangeNetwork
              networks={networks}
              setSelectedNetwork={setSelectedNetwork}
            />
          }
        />
        <Route path="/arrets" element={<Arrets />} />
        <Route path="/network/:networkId/stop/:stopId" element={<StopDepartures />} />
        <Route path="/infotrafic" element={<Infotrafic />} />
        <Route path="/mentions" element={<Mentions />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />

        {/* Si la route ne correspond à rien, redirection vers "/" */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      
      <Footer />
    </>
  );
};

// ---------------------------------------------------
// COMPONENT: Home
// ---------------------------------------------------
const Home = ({ networks, selectedNetwork, setSelectedNetwork }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  // On regroupe les réseaux par département, en tenant compte du filtre de recherche
  const grouped = groupNetworksByDept(networks, searchTerm);

  // On récupère la liste des clés (départements)
  let deptKeys = Object.keys(grouped);

  // On veut que "Oise" apparaisse en premier
  deptKeys.sort((a, b) => {
    if (a === 'Oise') return -1;
    if (b === 'Oise') return 1;
    return a.localeCompare(b);
  });

  return (
    <>
      <Helmet>
        <title>Bus Connect - Accueil</title>
      </Helmet>

      <Container className="mt-4">
        <div
          style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            maxWidth: '600px',
            margin: 'auto',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          <p style={{ fontSize: '14px', margin: 0 }}>
            Bienvenue sur Bus Connect. <br />
            Pour commencer, choisissez le réseau de bus dont vous souhaitez consulter les horaires.
          </p>
        </div>

        <div
          className="my-4"
          style={{
            maxWidth: "500px",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <BiSearch
            size={20}
            color="#0072ce"
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
            }}
          />
          <FormControl
            type="text"
            placeholder="Rechercher votre réseau de bus..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              paddingLeft: "35px",
              borderRadius: "5px",
              border: "1px solid #0072ce",
            }}
          />
        </div>

        {deptKeys.map((dept) => {
          const networksList = grouped[dept];
          // On récupère le code du département (ex : 60 pour Oise)
          const depCode = networksList?.[0]?.dep_code || ""; 

          // Séparation première lettre + le reste
          const firstLetter = dept.slice(0,1);
          const rest = dept.slice(1);

          return (
            <div key={dept} style={{ margin: '20px 0' }}>
              <div
                style={{
                  width: "calc(100% - 4px)",
                  borderRadius: "15px",
                  background: "linear-gradient(135deg, #0A78A4 0%, #66A7C5 100%)",
                  color: "#fff",
                  padding: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginBottom: "10px",
                  justifyContent: "space-between" // Pour séparer le nom et le code
                }}
              >
                {/* Icône supprimée */}

                <h3 style={{ fontWeight: "bold", margin: 0 }}>
                  <span style={{ fontSize: "1.2em" }}>{firstLetter}</span>
                  {rest}
                </h3>
                
                {/* Code du département à droite, en blanc, plus gros, gras, et en opacité à 30% */}
                <span 
                  style={{
                    fontWeight: "bold",
                    fontSize: "2.4em",   // grossi la police
                    opacity: 0.7,       // 30% d'opacité
                    lineHeight: 1       // évite d'agrandir la div
                  }}
                >
                  {depCode}
                </span>
              </div>
              <ListGroup
                style={{
                  border: '1px solid #0A78A4',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  margin: '0',
                }}
              >
                {networksList.map((network, index) => {
                  const [leftText, rightText] = network.agency_name?.split(' - ') || ['', ''];

                  return (
                    <div
                      key={network.network_id}
                      onClick={() => handleNetworkSelect(network.network_id, setSelectedNetwork)}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        cursor: 'pointer',
                        display: 'block',
                      }}
                    >
                      <ListGroup.Item
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          border: 'none',
                          borderBottom:
                            index !== networksList.length - 1
                              ? '1px solid #0A78A4'
                              : 'none',
                          padding: '10px 15px',
                          backgroundColor: '#fff',
                          color: '#000',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={`/logo/${network.network_id}.png`}
                            alt={leftText}
                            className="agency-logo"
                            style={{ width: "30px", height: "30px", marginRight: "10px" }}
                          />
                          <span className="left-text">{leftText}</span>
                        </div>
                        <span className="right-text">{rightText}</span>
                      </ListGroup.Item>
                    </div>
                  );
                })}
              </ListGroup>
            </div>
          );
        })}
      </Container>
    </>
  );
};

// Regroupe par département + filtre par nom
function groupNetworksByDept(networks, searchTerm) {
  const filteredNetworks = networks.filter((network) =>
    (network.agency_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  return filteredNetworks.reduce((acc, network) => {
    const dept = network.dep_name || 'Autre';
    if (!acc[dept]) {
      acc[dept] = [];
    }
    acc[dept].push(network);
    return acc;
  }, {});
}

// Sélection d'un réseau => mise à jour du cookie + state
function handleNetworkSelect(networkId, setSelectedNetwork) {
  Cookies.set('selectedNetwork', networkId, { expires: 365 });
  setSelectedNetwork(networkId);
}


// ---------------------------------------------------
// COMPONENT: ChangeNetwork
// ---------------------------------------------------
const ChangeNetwork = ({ networks, setSelectedNetwork }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleNetworkSelect = (networkId) => {
    Cookies.set("selectedNetwork", networkId, { expires: 365 });
    setSelectedNetwork(networkId);
    navigate(`/network/${networkId}`);
  };

  // Filtre
  const filteredNetworks = networks.filter((network) =>
    (network.agency_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  // Regroupement par département
  const groupedByDepartment = filteredNetworks.reduce((acc, network) => {
    const dept = network.dep_name || 'Autre';
    if (!acc[dept]) {
      acc[dept] = [];
    }
    acc[dept].push(network);
    return acc;
  }, {});

  // Tri : "Oise" en premier
  let deptKeys = Object.keys(groupedByDepartment);
  deptKeys.sort((a, b) => {
    if (a === 'Oise') return -1;
    if (b === 'Oise') return 1;
    return a.localeCompare(b);
  });

  return (
    <Container className="mt-4 text-center">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            width: "calc(100% - 4px)",
            borderRadius: "15px",
            background: "linear-gradient(135deg, #0A78A4 0%, #66A7C5 100%)",
            color: "#fff",
            padding: "5px",
            boxShadow: "0 2px 8px rgba(10, 120, 164, 0.23)",
            textAlign: "center",
            position: "relative",
          }}
        >
          <h2 style={{ fontWeight: "bold", marginTop: "10px" }}>
            Choix du réseau
          </h2>
        </div>
      </div>

      <div
        className="my-4"
        style={{
          maxWidth: "500px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <BiSearch
          size={20}
          color="#0072ce"
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
          }}
        />
        <FormControl
          type="text"
          placeholder="Rechercher votre réseau de bus..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            paddingLeft: "35px",
            borderRadius: "5px",
            border: "1px solid #0072ce",
          }}
        />
      </div>

      {deptKeys.map((dept) => {
        const networksList = groupedByDepartment[dept];
        // Récupération du code du département
        const depCode = networksList?.[0]?.dep_code || "";

        const firstLetter = dept.slice(0,1);
        const rest = dept.slice(1);

        return (
          <div key={dept} style={{ margin: '20px 0' }}>
            <div
              style={{
                width: "calc(100% - 4px)",
                borderRadius: "15px",
                background: "linear-gradient(135deg, #0A78A4 0%, #66A7C5 100%)",
                color: "#fff",
                padding: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                position: "relative",
                marginBottom: "10px",
                justifyContent: "space-between"
              }}
            >
              {/* Icône supprimée */}

              <h3 style={{ fontWeight: "bold", margin: 0 }}>
                <span style={{ fontSize: "1.2em" }}>{firstLetter}</span>
                {rest}
              </h3>

              {/* Code du département à droite, plus grand, gras, opacité 30%, line-height 1 */}
              <span 
                style={{
                  fontWeight: "bold",
                  fontSize: "2.4em",
                  opacity: 0.7,
                  lineHeight: 1
                }}
              >
                {depCode}
              </span>
            </div>
            <ListGroup
              style={{
                border: "1px solid #0A78A4",
                borderRadius: "5px",
                overflow: "hidden",
                margin: "20px 0",
              }}
            >
              {networksList.map((network, index) => {
                const [leftText, rightText] = network.agency_name?.split(" - ") || ['', ''];

                return (
                  <div
                    key={network.network_id}
                    onClick={() => handleNetworkSelect(network.network_id)}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        border: "none",
                        borderBottom:
                          index !== networksList.length - 1
                            ? "1px solid #0A78A4"
                            : "none",
                        padding: "10px 15px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={`/logo/${network.network_id}.png`}
                          alt={leftText}
                          className="agency-logo"
                          style={{ width: "30px", height: "30px", marginRight: "10px" }}
                        />
                        <span className="left-text">{leftText}</span>
                      </div>
                      <span className="right-text">{rightText}</span>
                    </ListGroup.Item>
                  </div>
                );
              })}
            </ListGroup>
          </div>
        );
      })}
    </Container>
  );
};

export default () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);
