// Mentions.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Mentions = () => {
  return (
    <>
      {/* Helmet pour définir le titre de l'onglet */}
      <Helmet>
        <title>Bus Connect - Mentions légales</title>
      </Helmet>

      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          padding: '40px',
          lineHeight: '1.6',
          maxWidth: '800px',
          margin: '0 auto'
        }}
      >
        <h1
          style={{
            fontSize: '2em',
            textAlign: 'center',
            marginBottom: '40px'
          }}
        >
          Mentions Légales
        </h1>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>
            Éditeur de l’application
          </h2>
          <p>
            Nom de l’application : <strong>Bus Connect</strong>
            <br />
            Contact par e-mail : <strong>weibelclement@gmail.com</strong>
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Hébergeur</h2>
          <p>
            <strong>OVH (SAS OVH)</strong>
            <br />
            Siège social : <strong>2 rue Kellermann – 59100 Roubaix – France</strong>
            <br />
            Site Web : <strong>www.ovh.com</strong>
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>
            Délégué à la Protection des Données (DPD) / Responsable des données
            personnelles
          </h2>
          <p>
            Contact par e-mail : <strong>weibelclement@gmail.com</strong>
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>
            Données Open Data & Temps Réel
          </h2>
          <p>
            L’application <strong>Bus Connect</strong> utilise des données de transport en
            temps réel, fournies notamment via la plateforme officielle de
            l’Open Data des transports en France :{' '}
            <strong>transport.data.gouv.fr</strong>.
          </p>
          <p>
            <strong>Utilisation de l’Open Data :</strong>
            <br />
            Les données Open Data sont accessibles librement et gratuitement,
            favorisant la transparence, l’innovation et la participation
            citoyenne. En utilisant ces informations, <strong>Bus Connect</strong> rend plus
            aisée l’accès aux données de transport. Les horaires, passages et
            disponibilités des véhicules proviennent de sources publiques ou
            d’opérateurs partenaires, et sont réutilisés conformément aux
            conditions d’utilisation des données ouvertes.
          </p>
          <p>
            <strong>Temps Réel :</strong>
            <br />
            Le temps réel repose sur la mise à jour continue des positions et
            horaires des bus. Les informations, fournies par les opérateurs de
            transport et centralisées sur des plateformes Open Data,
            permettent à <strong>Bus Connect</strong> d’afficher des horaires actualisés.
            Toutefois, retards, annulations ou perturbations peuvent survenir,
            indépendamment de la volonté de l’application.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>
            Absence de Garantie & Responsabilité
          </h2>
          <p>
            Les informations fournies par <strong>Bus Connect</strong> sont présentées à titre
            indicatif et proviennent de sources tierces. L’application ne
            garantit ni la disponibilité ni l’exactitude absolues des données.
            En cas de problème (par exemple, un bus manquant), aucune
            réclamation ne sera prise en compte. L’utilisateur est invité à
            contacter directement l’opérateur de transport concerné pour toute
            demande ou réclamation. Pour obtenir les coordonnées d’un opérateur,
            une demande peut être effectuée via le chat d’aide présent dans
            l’application.
          </p>
        </section>
      </div>
    </>
  );
};

export default Mentions;
