import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Modal,
  ListGroup,
  FormControl,
  Placeholder,
  Spinner,
  Toast,
  ToastContainer
} from 'react-bootstrap';

import { IoIosArrowForward } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { FaSearch, FaStar } from "react-icons/fa";
import { FaCircleCheck, FaBusSimple } from "react-icons/fa6";
import { GiBusStop } from "react-icons/gi";

// --- ICI on ajoute nos icônes pour l’alerte
import { RiSnowyFill } from "react-icons/ri";
import { IoIosWarning } from "react-icons/io";
import { FaBurst } from "react-icons/fa6";
// -- fin ajout

// --- ÉTOILES POUR LES FAVORIS (ajout/suppression)
import { MdOutlineStar, MdOutlineStarBorderPurple500 } from "react-icons/md";

import { Helmet } from 'react-helmet';
import './App.css';

// Votre clé Mapbox
const MAPBOX_ACCESS_TOKEN = "pk.xxx_VOTRE_CLE_ICI_xxx";

function BusLines() {
  const { networkId } = useParams();
  const navigate = useNavigate();

  // Cache pour éviter de recharger constamment les lignes
  const requestCacheRef = useRef(new Map());

  // searchCache : { "AXO_gare": [ stops... ], "TOHM_gare": [ stops... ], ... }
  const [searchCache, setSearchCache] = useState(() => {
    const stored = localStorage.getItem('searchCache');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch {
        return {};
      }
    }
    return {};
  });

  const [lines, setLines] = useState(null);
  const [loadingLines, setLoadingLines] = useState(true);

  const [favorites, setFavorites] = useState(null);
  const [loadingFavorites, setLoadingFavorites] = useState(true);

  const [networksList, setNetworksList] = useState([]);

  const [stops, setStops] = useState([]);
  const [busStopSearchTerm, setBusStopSearchTerm] = useState('');
  const [loadingStops, setLoadingStops] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBox, setSelectedBox] = useState(null);

  const [selectedLine, setSelectedLine] = useState(null);
  const [directions, setDirections] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const cancelTokenSourceRef = useRef(null);

  // Alerte - start
  const [alert, setAlert] = useState(null);
  const [loadingAlert, setLoadingAlert] = useState(true);
  useEffect(() => {
    setLoadingAlert(true);
    axios
      .get(`/getAlerts.php?network_id=${networkId}`)
      .then((response) => {
        if (response.data && response.data.id) {
          setAlert(response.data);
        } else {
          setAlert(null);
        }
      })
      .catch((error) => {
        console.error('Error fetching alert!', error);
        setAlert(null);
      })
      .finally(() => setLoadingAlert(false));
  }, [networkId]);
  // Alerte - end

  // -------------------------------------------------------------------------
  // Charger les lignes
  // -------------------------------------------------------------------------
  useEffect(() => {
    setLoadingLines(true);
    const cacheKey = `busLines?network_id=${networkId}`;

    if (requestCacheRef.current.has(cacheKey)) {
      setLines(requestCacheRef.current.get(cacheKey));
      setLoadingLines(false);
      return;
    }

    axios
      .get(`/getBusLines.php?network_id=${networkId}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setLines(response.data);
          requestCacheRef.current.set(cacheKey, response.data);
        } else {
          console.error('API response is not an array:', response.data);
          setLines([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching bus lines!', error);
        setLines([]);
      })
      .finally(() => {
        setLoadingLines(false);
      });
  }, [networkId]);

  // -------------------------------------------------------------------------
  // Charger les favoris (stockés en cookie)
  // -------------------------------------------------------------------------
  useEffect(() => {
    setLoadingFavorites(true);
    const favs = getFavoritesFromCookie();
    setFavorites(favs);
    setLoadingFavorites(false);
  }, []);

  // -------------------------------------------------------------------------
  // Charger la liste des réseaux
  // -------------------------------------------------------------------------
  useEffect(() => {
    axios
      .get('/getNetworks.php')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setNetworksList(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch((error) => console.error('Error fetching networks:', error));
  }, []);

  // -------------------------------------------------------------------------
  // Recherche d'arrêts => DEBOUNCE (400ms) pour limiter les requêtes
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (busStopSearchTerm.trim().length === 0) {
      setStops([]);
      return;
    }

    // DEBOUNCE 400ms
    const delayDebounceFn = setTimeout(() => {
      setLoadingStops(true);

      const typedLower = busStopSearchTerm.trim().toLowerCase();
      const typedKeyPrefix = `${networkId}_${typedLower}`;

      // 1) On regarde dans le cache local
      const partialMatches = Object.keys(searchCache).filter((key) =>
        key.startsWith(typedKeyPrefix)
      );

      if (partialMatches.length > 0) {
        // On fusionne les arrêts déjà en cache
        const mergedStops = new Set();
        partialMatches.forEach((k) => {
          const arr = searchCache[k];
          arr.forEach((stopObj) => mergedStops.add(JSON.stringify(stopObj)));
        });
        const mergedArray = Array.from(mergedStops).map((s) => JSON.parse(s));

        // Mise à jour
        setStops(mergedArray);
        setLoadingStops(false);

        // Enrichir en city (en asynchrone)
        enrichStopsWithCity(mergedArray, typedKeyPrefix, /*isPartial=*/true);
      } else {
        // Sinon, on appelle l'API PHP
        if (cancelTokenSourceRef.current) {
          cancelTokenSourceRef.current.cancel('New request');
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        cancelTokenSourceRef.current = source;

        axios
          .get(
            `/getArrets.php?network_id=${encodeURIComponent(
              networkId
            )}&searchTerm=${encodeURIComponent(busStopSearchTerm)}`,
            { cancelToken: source.token }
          )
          .then((response) => {
            if (Array.isArray(response.data)) {
              // Stocker dans searchCache
              updateSearchCache(typedKeyPrefix, response.data);

              // Afficher
              setStops(response.data);
              setLoadingStops(false);

              // Enrichir en city
              enrichStopsWithCity(response.data, typedKeyPrefix, false);
            } else {
              console.error('Expected an array of stops but got:', response.data);
              setStops([]);
              setLoadingStops(false);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Requête annulée
            } else {
              console.error('Error fetching stops:', error);
              setStops([]);
              setLoadingStops(false);
            }
          });
      }
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [busStopSearchTerm, networkId, searchCache]);

  // -------------------------------------------------------------------------
  // updateSearchCache
  // -------------------------------------------------------------------------
  const updateSearchCache = (key, arrStops) => {
    const newCache = { ...searchCache };
    newCache[key] = arrStops;
    setSearchCache(newCache);
    localStorage.setItem('searchCache', JSON.stringify(newCache));
  };

  // -------------------------------------------------------------------------
  // enrichStopsWithCity (OPTIMISÉ)
  // -------------------------------------------------------------------------
  const enrichStopsWithCity = async (arrStops, typedKey, isPartial) => {
    // Limite à 20 arrêts max pour éviter trop d'appels Mapbox
    const LIMIT = 20;
    if (!arrStops || arrStops.length === 0) return;

    // Copie
    const toEnrich = arrStops.map((s) => ({ ...s, city: s.city || '' }));
    let changed = false;

    // On filtre seulement ceux qui n'ont pas city ET pas de city en localStorage
    const stopsNeedingCity = [];
    for (let s of toEnrich) {
      if (!s.city) {
        const cityKey = `cityCache_${s.stop_lat}_${s.stop_lon}`;
        const cachedCity = localStorage.getItem(cityKey);
        if (cachedCity) {
          s.city = cachedCity;
          changed = true;
        } else {
          stopsNeedingCity.push(s);
        }
      }
    }

    // On en prend max 20
    const stopsLimited = stopsNeedingCity.slice(0, LIMIT);

    // Lance les appels Mapbox en parallèle
    const promises = stopsLimited.map(async (stopObj) => {
      const c = await fetchCityFromMapbox(stopObj.stop_lon, stopObj.stop_lat);
      if (c) {
        stopObj.city = c;
        localStorage.setItem(`cityCache_${stopObj.stop_lat}_${stopObj.stop_lon}`, c);
        changed = true;
      }
    });

    await Promise.all(promises);

    if (changed) {
      setStops((oldStops) => {
        const oldMap = new Map();
        oldStops.forEach((o) => {
          oldMap.set(o.stop_id, o);
        });
        toEnrich.forEach((n) => {
          oldMap.set(n.stop_id, n);
        });
        return Array.from(oldMap.values());
      });

      // Si pas partiel => on réécrit dans le cache local
      if (!isPartial) {
        updateSearchCache(typedKey, toEnrich);
      }
    }
  };

  // -------------------------------------------------------------------------
  // fetchCityFromMapbox
  // -------------------------------------------------------------------------
  const fetchCityFromMapbox = async (lon, lat) => {
    try {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${MAPBOX_ACCESS_TOKEN}&limit=1`;
      const resp = await fetch(url);
      if (!resp.ok) {
        console.error('Mapbox API error', resp.status, resp.statusText);
        return '';
      }
      const data = await resp.json();
      if (data && Array.isArray(data.features) && data.features.length > 0) {
        const f = data.features[0];
        let cityName = '';

        if (f.context) {
          const placeOrLoc = f.context.find(c => c.id.includes('place') || c.id.includes('locality'));
          if (placeOrLoc && placeOrLoc.text) {
            cityName = placeOrLoc.text;
          }
        }
        if (!cityName && f.text) {
          cityName = f.text;
        }
        return cityName;
      }
      return '';
    } catch (err) {
      console.error('Error calling Mapbox', err);
      return '';
    }
  };

  // -------------------------------------------------------------------------
  // Favoris (cookie)
  // -------------------------------------------------------------------------
  const getFavoritesFromCookie = () => {
    const name = 'favorites=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let favorites = [];
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        favorites = JSON.parse(c.substring(name.length, c.length));
        break;
      }
    }
    return Array.isArray(favorites) ? favorites : [];
  };

  const setFavoritesToCookie = (favoritesArray) => {
    const now = new Date();
    now.setTime(now.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + now.toUTCString();
    document.cookie =
      'favorites=' +
      JSON.stringify(favoritesArray) +
      ';' +
      expires +
      ';path=/';
  };

  const removeFavorite = (network_id, route_id) => {
    let currentFavs = getFavoritesFromCookie();
    const favToRemove = currentFavs.find(
      (fav) => fav.network_id === network_id && fav.route_id === route_id
    );
    currentFavs = currentFavs.filter(
      (fav) => !(fav.network_id === network_id && fav.route_id === route_id)
    );
    setFavoritesToCookie(currentFavs);
    setFavorites(currentFavs);

    if (favToRemove) {
      const agencyShortName = getAgencyShortName(favToRemove.network_id);
      setToastMessage(
        <>
          La ligne {favToRemove.route_short_name} (
          <strong>{agencyShortName}</strong>) a été retirée de vos favoris
        </>
      );
      setShowToast(true);
    }
  };

  // -------------------------------------------------------------------------
  // AJOUT / BASCULE FAVORI
  // -------------------------------------------------------------------------
  const isFavorite = (lineObj) => {
    if (!favorites) return false;
    return favorites.some(
      (fav) =>
        fav.network_id === lineObj.network_id && fav.route_id === lineObj.route_id
    );
  };

  const addFavorite = (lineObj) => {
    let currentFavs = getFavoritesFromCookie();
    const alreadyInFavs = currentFavs.some(
      (fav) =>
        fav.network_id === lineObj.network_id &&
        fav.route_id === lineObj.route_id
    );
    if (!alreadyInFavs) {
      currentFavs.push(lineObj);
      setFavoritesToCookie(currentFavs);
      setFavorites(currentFavs);

      const agencyShortName = getAgencyShortName(lineObj.network_id);
      setToastMessage(
        <>
          La ligne {lineObj.route_short_name} (
          <strong>{agencyShortName}</strong>) a été ajoutée à vos favoris
        </>
      );
      setShowToast(true);
    }
  };

  const toggleFavorite = (lineObj) => {
    if (isFavorite(lineObj)) {
      removeFavorite(lineObj.network_id, lineObj.route_id);
    } else {
      addFavorite(lineObj);
    }
  };

  // -------------------------------------------------------------------------
  // getAgencyShortName
  // -------------------------------------------------------------------------
  const getAgencyShortName = (network_id) => {
    const net = networksList.find((n) => n.network_id === network_id);
    if (net && net.agency_name) {
      return net.agency_name.split(' - ')[0];
    }
    return network_id;
  };

  // -------------------------------------------------------------------------
  // Filtrage des lignes / favoris
  // -------------------------------------------------------------------------
  const filteredLines = Array.isArray(lines)
    ? lines.filter(
        (line) =>
          (line.route_short_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
          (line.route_long_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
      )
    : [];

  const filteredFavorites = Array.isArray(favorites)
    ? favorites.filter(
        (fav) =>
          (fav.route_short_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
          (fav.route_long_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
      )
    : [];
  const reversedFavorites = [...filteredFavorites].reverse();

  // -------------------------------------------------------------------------
  // Couleur clair/foncé ?
  // -------------------------------------------------------------------------
  const isColorLight = (color) => {
    if (!color) return true;
    const c = color.startsWith('#') ? color.slice(1) : color;
    const rgb = parseInt(c, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 128;
  };

  // -------------------------------------------------------------------------
  // handleShowModal (pour choisir la direction)
  // -------------------------------------------------------------------------
  const handleShowModal = (line) => {
    if (line.etat === 0) return;
    const lineWithNetwork = {
      ...line,
      network_id: line.network_id || networkId,
    };
    setSelectedLine(lineWithNetwork);

    axios
      .get(`/getBusLines.php?route_id=${lineWithNetwork.route_id}&network_id=${lineWithNetwork.network_id}`)
      .then((response) => {
        const directionsData = response.data;
        // S'il n'y a qu'une seule direction, on va directement sur /schedule
        if (Object.keys(directionsData).length === 1) {
          const directionId = Object.keys(directionsData)[0];
          navigate(
            `/schedule/${lineWithNetwork.network_id}/${lineWithNetwork.route_id}/${directionId}`
          );
        } else {
          // Sinon on affiche la modal pour choisir la direction
          setDirections(directionsData);
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching directions!', error);
      });
  };

  const handleSelectDirection = (direction_id) => {
    if (selectedLine && selectedLine.etat === 1) {
      navigate(`/schedule/${selectedLine.network_id}/${selectedLine.route_id}/${direction_id}`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLine(null);
    setDirections([]);
  };

  // -------------------------------------------------------------------------
  // stopsToShow (pour la liste sous la barre de recherche)
  // -------------------------------------------------------------------------
  let stopsToShow = [];
  if (busStopSearchTerm.trim().length > 0 && !loadingStops) {
    const seen = new Set();
    for (const stop of stops) {
      const key = `${stop.stop_name}_${stop.city || ''}`;
      if (!seen.has(key)) {
        seen.add(key);
        stopsToShow.push(stop);
      }
    }
  }

  // -------------------------------------------------------------------------
  // Gestion box line / stop
  // -------------------------------------------------------------------------
  const handleBoxClick = (type) => {
    // On ne fait plus rien si c'est "stop", car c'est désactivé
    if (type === 'line') {
      setSelectedBox('line');
      setBusStopSearchTerm('');
    }
  };

  // Styles
  const boxStyleLeft = (isSelected) => {
    let style = {
      flex: '1',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#f9f9f9',
      borderRadius: '0 10px 10px 0',
      padding: '10px 20px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer',
      height: '60px',
      fontWeight: 'bold',
      gap: '10px',
    };
    if (isSelected) {
      style.borderTop = '2px solid #0a78a4';
      style.borderRight = '2px solid #0a78a4';
      style.borderBottom = '2px solid #0a78a4';
      style.borderLeft = 'none';
    }
    return style;
  };

  const boxStyleRightDisabled = {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#f9f9f9',
    borderRadius: '10px 0 0 10px',
    padding: '10px 20px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    height: '60px',
    fontWeight: 'bold',
    gap: '10px',
    cursor: 'not-allowed',
    opacity: 0.5,
    position: 'relative',
  };

  const getFullAgencyName = () => {
    const net = networksList.find((n) => n.network_id === networkId);
    if (net && net.agency_name) {
      return net.agency_name;
    }
    return 'Inconnu';
  };

  return (
    <>
      <Helmet>
        <title>Bus Connect - {getFullAgencyName()}</title>
      </Helmet>

      <Container className="my-1 d-flex align-items-center justify-content-center">
        <div className="w-100">

        {/* ----------------- Affichage de l’alerte (en haut) ----------------- */}
        {!loadingAlert && alert && (
          <div
            onClick={() => navigate(`/network/${networkId}/alert/${alert.id}`)}
            style={{
              backgroundColor: alert.color,
              padding: '15px',
              borderRadius: '10px',
              marginBottom: '20px',
              color: isColorLight(alert.color) ? 'black' : 'white',
              position: 'relative',
              overflow: 'hidden',
              cursor: 'pointer', // Indique que la div est cliquable
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {/* Définir la couleur du texte */}
            {(() => {
              const textColor = isColorLight(alert.color) ? 'black' : 'white';
              return (
                <>
                  {/* En-tête avec le titre et l'icône */}
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 style={{ 
                      margin: 0, 
                      fontWeight: 'bold', 
                      textTransform: 'uppercase', 
                      color: textColor 
                    }}>
                      {alert.title}
                    </h5>

                    <div>
                      {alert.icone == '1' && (
                        <RiSnowyFill 
                          style={{ 
                            fontSize: '24px', 
                            color: textColor 
                          }} 
                        />
                      )}
                      {alert.icone == '2' && (
                        <IoIosWarning 
                          style={{ 
                            fontSize: '24px', 
                            color: textColor 
                          }} 
                        />
                      )}
                      {alert.icone == '3' && (
                        <FaBurst 
                          style={{ 
                            fontSize: '24px', 
                            color: textColor 
                          }} 
                        />
                      )}
                    </div>
                  </div>
              
                  {/* Bouton "EN SAVOIR +" */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Empêche le déclenchement du clic sur la div
                      navigate(`/network/${networkId}/alert/${alert.id}`);
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      border: `2px solid ${textColor}`,
                      color: textColor,
                      padding: '10px 0',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      width: '100%',
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '10px',
                    }}
                  >
                    EN SAVOIR +
                  </button>
                </>
              );
            })()}
          </div>
        )}
        {/* ----------------- Fin alerte ----------------- */}

          {/* ----------------------- FAVORIS ----------------------- */}
          <div className="mb-4" style={{ marginTop: '0px' }}>
            <h4 style={{ display: 'flex', alignItems: 'center' }}>
              <FaStar />
              <span style={{ marginLeft: '5px' }}>Vos favoris</span>
            </h4>

            {favorites === null ? (
              <div
                className="favorites-container custom-scroll"
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '10px',
                  padding: '10px',
                  background: '#fafafa',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '400px',
                  marginBottom: '20px',
                }}
              >
                {[...Array(3)].map((_, i) => (
                  <div
                    key={i}
                    style={{
                      backgroundColor: '#eee',
                      borderRadius: '20px',
                      padding: '5px 15px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <Placeholder as="span" animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                ))}
              </div>
            ) : reversedFavorites.length > 0 ? (
              <div
                className="favorites-container custom-scroll"
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '10px',
                  padding: '10px',
                  background: '#fafafa',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '400px',
                  marginBottom: '20px',
                }}
              >
                {reversedFavorites.map((favLine) => {
                  const textColor = isColorLight(favLine.route_color)
                    ? 'black'
                    : 'white';
                  const iconColor = textColor === 'white' ? 'white' : 'black';
                  const agencyShortName = getAgencyShortName(favLine.network_id);

                  return (
                    <div
                      key={`${favLine.network_id}-${favLine.route_id}`}
                      className="favorite-item"
                      style={{
                        backgroundColor: `#${favLine.route_color}`,
                        borderRadius: '20px',
                        padding: '5px 15px',
                        color: textColor,
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                      }}
                      title={favLine.route_long_name}
                      onClick={(e) => {
                        if (e.target.closest('.delete-icon')) return;
                        const lineObj = {
                          route_id: favLine.route_id,
                          route_short_name: favLine.route_short_name,
                          route_long_name: favLine.route_long_name,
                          route_color: favLine.route_color,
                          network_id: favLine.network_id,
                          etat: favLine.etat !== undefined ? favLine.etat : 1,
                        };
                        handleShowModal(lineObj);
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {favLine.route_short_name} ({agencyShortName})
                      </span>
                      <span
                        className="delete-icon"
                        style={{
                          marginLeft: '8px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFavorite(favLine.network_id, favLine.route_id);
                        }}
                        title="Supprimer ce favori"
                      >
                        <TiDelete
                          style={{ color: iconColor, fontSize: '20px' }}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: '#b30000',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  textAlign: 'center',
                  maxWidth: '400px',
                  marginBottom: '20px',
                }}
              >
                Aucune ligne en favoris
              </div>
            )}
          </div>
          {/* ----------------------- FIN FAVORIS ----------------------- */}

          {/* ---- SECTION DE RECHERCHE (2 BOX) ---- */}
          <Row
            className="my-4"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              justifyContent: 'center',
            }}
          >
            <Col
              style={boxStyleLeft(selectedBox === 'line')}
              onClick={() => handleBoxClick('line')}
            >
              <span>Rechercher votre ligne</span>
              <FaSearch style={{ fontSize: '24px', color: '#0a78a4' }} />
            </Col>

            {/* Box arrêt désactivé */}
            <Col style={boxStyleRightDisabled}>
              <div
                style={{
                  opacity: 0.2,
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <GiBusStop style={{ fontSize: '24px', color: '#0a78a4' }} />
                <span style={{ marginLeft: '8px' }}>Rechercher votre arrêt</span>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  padding: '4px 8px',
                  borderRadius: '5px',
                  fontSize: '0.8em',
                  color: '#666',
                }}
              >
                prochainement disponible
              </div>
            </Col>
          </Row>

          {/* RECHERCHE LIGNE */}
          {selectedBox === 'line' && (
            <Row className="mb-4">
              <Col>
                <FormControl
                  type="text"
                  placeholder="Rechercher votre ligne..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ marginBottom: '10px', width: '100%' }}
                />
              </Col>
            </Row>
          )}

          {/* ---------------------- LISTE DES LIGNES ---------------------- */}
          <Row className="justify-content-center">
            <Col md="auto">
              {lines === null ? (
                <ListGroup style={{ minHeight: '400px' }}>
                  {[...Array(4)].map((_, index) => (
                    <ListGroup.Item
                      key={index}
                      style={{ cursor: 'wait', padding: '10px' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#e0e0e0',
                              width: '60px',
                              height: '30px',
                              borderRadius: '5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Placeholder as="span" animation="glow">
                              <Placeholder xs={2} bg="secondary" />
                            </Placeholder>
                          </div>
                          <div
                            style={{
                              backgroundColor: '#e0e0e0',
                              width: '150px',
                              height: '20px',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Placeholder as="span" animation="glow">
                              <Placeholder xs={6} bg="secondary" />
                            </Placeholder>
                          </div>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <>
                  <ListGroup>
                    {filteredLines.map((line) => {
                      const lineTextColor = isColorLight(line.route_color)
                        ? 'black'
                        : 'white';
                      const lineStyle =
                        line.etat === 0
                          ? {
                              opacity: '0.3',
                              pointerEvents: 'none',
                              userSelect: 'none',
                              cursor: 'default',
                            }
                          : {};

                      // Objet simplifié pour l'ajout / retrait en favoris
                      const lineObj = {
                        route_id: line.route_id,
                        route_short_name: line.route_short_name,
                        route_long_name: line.route_long_name,
                        route_color: line.route_color,
                        network_id: line.network_id || networkId,
                        etat: line.etat,
                        route_type: line.route_type
                      };

                      return (
                        <ListGroup.Item
                          key={line.route_id}
                          className="line-item"
                          onClick={() => handleShowModal(lineObj)}
                          style={{
                            cursor: line.etat === 1 ? 'pointer' : 'default',
                            ...lineStyle,
                          }}
                        >
                         

                          {/* Nom complet de la ligne + Icône étoile à gauche du short_name */}
                          <div className="line-name" style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Icône étoile pour ajouter/retirer en favori */}
                            <div
                              style={{ marginLeft: '-15px', marginRight: '8px'}}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFavorite(lineObj);
                              }}
                              title={
                                isFavorite(lineObj)
                                  ? 'Retirer des favoris'
                                  : 'Ajouter aux favoris'
                              }
                            >
                              {isFavorite(lineObj) ? (
                                <MdOutlineStar style={{ color: '#0a78a4', fontSize: '30px' }} />
                              ) : (
                                <MdOutlineStarBorderPurple500 style={{ color: '#555', fontSize: '30px' }} />
                              )}
                            </div>

                            <div
                            className="line-number-box"
                            style={{
                              backgroundColor: `#${line.route_color}`,
                              color: lineTextColor,
                            }}
                          >
                            {line.route_type === 715 ? (
                              <FaBusSimple style={{ color: lineTextColor }} />
                            ) : (
                              line.route_short_name
                            )}
                          </div>
                            {/* Le nom long de la ligne */}
                            {line.route_long_name}
                          </div>

                          <div className="line-arrow">
                            <IoIosArrowForward />
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  {selectedBox === 'line' &&
                    searchTerm.length > 0 &&
                    filteredLines.length === 0 && (
                      <div
                        style={{
                          backgroundColor: '#b30000',
                          color: 'white',
                          borderRadius: '20px',
                          padding: '10px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        Aucune ligne ne correspond à votre recherche.
                      </div>
                    )}
                </>
              )}
            </Col>
          </Row>
          {/* ---------------------- FIN LISTE DES LIGNES ---------------------- */}

          {/* Modal direction */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedLine ? selectedLine.route_long_name : ''}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="direction-list">
                {Object.entries(directions).map(
                  ([direction_id, terminus], index, arr) => {
                    const isFirst = index === 0;
                    const isLast = index === arr.length - 1;
                    const textColor =
                      selectedLine && isColorLight(selectedLine.route_color)
                        ? 'black'
                        : 'white';
                    return (
                      <li
                        key={direction_id}
                        className={`direction-item ${isFirst ? 'first' : ''} ${
                          isLast ? 'last' : ''
                        }`}
                        onClick={() => handleSelectDirection(direction_id)}
                        style={{
                          backgroundColor: selectedLine
                            ? `#${selectedLine.route_color}`
                            : '#ccc',
                          color: textColor,
                          position: 'relative',
                          cursor:
                            selectedLine && selectedLine.etat === 1
                              ? 'pointer'
                              : 'default',
                          opacity:
                            selectedLine && selectedLine.etat === 0 ? '0.3' : '1',
                          pointerEvents:
                            selectedLine && selectedLine.etat === 0
                              ? 'none'
                              : 'auto',
                        }}
                      >
                        <span className="direction-label" style={{ color: textColor }}>
                          Vers
                        </span>
                        {terminus}
                      </li>
                    );
                  }
                )}
              </ul>
            </Modal.Body>
          </Modal>

          {/* Toast pour la suppression ou l'ajout de favori */}
          <ToastContainer className="toast-fixed">
            <Toast
              show={showToast}
              onClose={() => setShowToast(false)}
              delay={5000}
              autohide
              bg="light"
            >
              <Toast.Header>
                <FaCircleCheck style={{ color: 'green', marginRight: '8px' }} />
                <strong className="me-auto">Favoris mis à jour</strong>
              </Toast.Header>
              <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </div>

        <style jsx>{`
          .favorites-container::-webkit-scrollbar {
            height: 8px;
          }
          .favorites-container::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
          }
          .favorites-container::-webkit-scrollbar-thumb {
            background: #0a78a4;
            border-radius: 4px;
          }
          .favorites-container::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          .toast-fixed {
            position: fixed !important;
            top: 1rem !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            z-index: 9999 !important;
          }
        `}</style>
      </Container>
    </>
  );
}

export default BusLines;
