import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import protobuf from 'protobufjs';
import { Container, ListGroup } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { IoCaretBackCircleOutline } from "react-icons/io5";
import './StopDepartures.css';

// Compteur global de requêtes (pour le debug)
let requestCount = 0;

// Ta clé Mapbox (tu peux la placer en variable d'environnement si besoin)
const MAPBOX_TOKEN = "pk.eyJ1Ijoid2NsZW1lbnQ2MCIsImEiOiJjbDEyOXRlaHEyZzkyM2JrYjJnN3ZrY2JrIn0.JrVxUKnkgR1vx8FWsNp9tQ";

function StopDepartures() {
  const { networkId, stopId } = useParams();
  
  const [initialDepartures, setInitialDepartures] = useState(null);
  const [stopName, setStopName] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [cityName, setCityName] = useState(null);

  const rootRef = useRef(null);
  const gtfsHorairesRef = useRef(null);
  const departuresRef = useRef([]);
  const domRefs = useRef({});
  const intervalIdRef = useRef(null);
  const listGroupRef = useRef(null);

  /**
   * Au montage, on charge le proto GTFS-RT
   * et on fetch les départs statiques (théoriques).
   */
  useEffect(() => {
    protobuf.load('/protos/gtfs-realtime.proto')
      .then(loadedRoot => {
        rootRef.current = loadedRoot;
        fetchDepartures();
      })
      .catch(err => console.error('Error loading proto:', err));

    // Cleanup
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, [networkId, stopId]);

  /**
   * Après avoir récupéré lat/long, on va chercher la ville (Mapbox)
   * seulement si pas déjà en cache localStorage.
   */
  useEffect(() => {
    if (latitude && longitude) {
      getCityNameFromCacheOrAPI(latitude, longitude).then(city => {
        setCityName(city);
      });
    }
  }, [latitude, longitude]);

  /**
   * Récupère (ou non) le nom de la ville depuis localStorage ou Mapbox
   */
  async function getCityNameFromCacheOrAPI(lat, lon) {
    const cacheKey = `cityName_${lat}_${lon}`;
    const cachedCity = localStorage.getItem(cacheKey);
    if (cachedCity) {
      // On évite un nouvel appel à Mapbox
      console.log("Using cached city name:", cachedCity);
      return cachedCity;
    }

    // Sinon on appelle Mapbox en reverse-geocoding
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${MAPBOX_TOKEN}&language=fr`;

    try {
      const response = await fetch(url);
      const geoData = await response.json();
      if (geoData && geoData.features && geoData.features.length > 0) {
        // On parcourt le contexte pour récupérer la "commune"/"ville"
        let city = null;
        const feat = geoData.features[0];
        if (feat.context) {
          for (let c of feat.context) {
            // city / place / locality / region
            if (c.id.startsWith("place")) {
              city = c.text;
              break;
            } else if (c.id.startsWith("locality")) {
              city = c.text;
              break;
            } else if (c.id.startsWith("region")) {
              city = c.text;
              break;
            }
          }
        }
        if (!city) {
          // Au pire on prend le text du feature principal
          city = feat.text;
        }
        // On enregistre en cache
        localStorage.setItem(cacheKey, city);
        return city;
      }
    } catch (err) {
      console.error("Error fetching city from Mapbox:", err);
    }
    // On renvoie null si échec
    return null;
  }

  /**
   * 1) Récupération initiale des départs (théoriques)
   * 2) Application immédiate des données temps réel
   * 3) Filtrage + tri
   * 4) Start Interval (pour re-rafraîchir en boucle)
   */
  function fetchDepartures() {
    requestCount++;
    console.log(`[fetchDepartures] Requête n°${requestCount} : /getNextDepartures.php`);

    axios.get(`/getNextDepartures.php?network_id=${encodeURIComponent(networkId)}&stop_id=${encodeURIComponent(stopId)}`)
      .then(async response => {
        const data = response.data;

        if (data && Array.isArray(data.departures)) {
          // On stocke les départs en mémoire
          departuresRef.current = data.departures.map(d => ({ ...d, delay: null }));
          gtfsHorairesRef.current = data.gtfs_horaires || null;

          // On met à jour le nom de l'arrêt
          setStopName(data.stop_name && data.stop_name.trim() !== '' ? data.stop_name : null);

          // On met à jour la latitude/longitude
          setLatitude(data.latitude || null);
          setLongitude(data.longitude || null);

          // Application immédiate du temps réel
          await applyRealTimeImmediately();

          // Filtrage + tri
          filterAndSort();

          // On "fige" ce tableau pour le rendu
          setInitialDepartures([...departuresRef.current]);

          // On lance l'interval pour rafraîchir
          startInterval();
        } else {
          console.error('Invalid data format:', data);
          departuresRef.current = [];
          gtfsHorairesRef.current = null;
          setInitialDepartures([]);
          setStopName(null);
          setLatitude(null);
          setLongitude(null);
        }
      })
      .catch(error => console.error('Error fetching departures:', error));
  }

  /**
   *  Appel direct du flux GTFS-RT + Mise à jour de departuresRef
   *  avant même le premier rendu "final"
   */
  async function applyRealTimeImmediately() {
    const root = rootRef.current;
    const gtfsHoraires = gtfsHorairesRef.current;

    if (!root || !gtfsHoraires || departuresRef.current.length === 0) return;

    try {
      requestCount++;
      console.log(`[applyRealTimeImmediately] Requête n°${requestCount} : fetch GTFS-RT => ${gtfsHoraires}`);

      const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
      const response = await fetch(gtfsHoraires);
      const arrayBuffer = await response.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      const message = FeedMessage.decode(buffer);
      const feed = FeedMessage.toObject(message, { longs: String, defaults: true });

      const realTimeUpdates = {};

      if (feed.entity) {
        feed.entity.forEach(ent => {
          if (ent.tripUpdate && ent.tripUpdate.trip && ent.tripUpdate.stopTimeUpdate) {
            const rtTripId = ent.tripUpdate.trip.tripId;
            ent.tripUpdate.stopTimeUpdate.forEach(stu => {
              if (stu.stopId && (stu.departure || stu.arrival)) {
                const rtTime = stu.departure?.time ?? stu.arrival?.time ?? null;
                const rtDelay = stu.departure?.delay ?? stu.arrival?.delay ?? null;
                if (rtTime !== null) {
                  realTimeUpdates[rtTripId + '|' + stu.stopId] = { time: rtTime, delay: rtDelay };
                }
              }
            });
          }
        });
      }

      // Mise à jour des départs
      departuresRef.current = departuresRef.current.map(d => {
        const key = d.trip_id + '|' + d.stop_id;
        if (realTimeUpdates[key]) {
          const { time: rtTime, delay: rtDelay } = realTimeUpdates[key];
          const normalized = normalizeTimeFromTimestamp(rtTime);
          return {
            ...d,
            departure_time: normalized,
            delay: delayToString(rtDelay)
          };
        } else {
          const normalized = normalizeTimeStr(d.departure_time);
          return {
            ...d,
            departure_time: normalized,
            delay: "Temps théorique"
          };
        }
      });
    } catch (err) {
      console.error('Error applying real-time data immediately:', err);
    }
  }

  /**
   * Interval = on rafraîchit les données toutes les 8 secondes,
   * pour limiter le nombre de requêtes c'est un délai "raisonnable".
   */
  function startInterval() {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      refreshData();
    }, 8000);
  }

  /**
   * 1) Rafraîchit la liste (théorique) 
   * 2) Applique l'update temps réel
   */
  function refreshData() {
    requestCount++;
    console.log(`[refreshData] Requête n°${requestCount} : /getNextDepartures.php`);

    axios.get(`/getNextDepartures.php?network_id=${encodeURIComponent(networkId)}&stop_id=${encodeURIComponent(stopId)}`)
      .then(response => {
        const data = response.data;
        if (data && Array.isArray(data.departures)) {
          const updated = data.departures;
          const updatedMap = {};

          for (let u of updated) {
            updatedMap[u.trip_id + '|' + u.stop_id] = u;
          }

          // On recycle l'ordre existant et on ne rajoute pas de nouveaux trips
          departuresRef.current = departuresRef.current.map(d => {
            const key = d.trip_id + '|' + d.stop_id;
            if (updatedMap[key]) {
              const normalized = normalizeTimeStr(updatedMap[key].departure_time);
              return {
                ...d,
                departure_time: normalized,
              };
            } else {
              return d;
            }
          });

          gtfsHorairesRef.current = data.gtfs_horaires || null;
          if (data.stop_name && data.stop_name.trim() !== '') {
            setStopName(data.stop_name);
          } else {
            setStopName(null);
          }

          // On update la latitude/longitude
          setLatitude(data.latitude || null);
          setLongitude(data.longitude || null);

          updateWithRealTime();
        }
      })
      .catch(error => console.error('Error refreshing departures:', error));
  }

  /**
   * On applique les données temps réel après le rafraîchissement théorique
   */
  async function updateWithRealTime() {
    const root = rootRef.current;
    const gtfsHoraires = gtfsHorairesRef.current;

    if (!root || !gtfsHoraires || departuresRef.current.length === 0) return;

    try {
      requestCount++;
      console.log(`[updateWithRealTime] Requête n°${requestCount} : fetch GTFS-RT => ${gtfsHoraires}`);

      const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
      const response = await fetch(gtfsHoraires);
      const arrayBuffer = await response.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      const message = FeedMessage.decode(buffer);
      const feed = FeedMessage.toObject(message, { longs: String, defaults: true });

      const realTimeUpdates = {};

      if (feed.entity) {
        feed.entity.forEach(ent => {
          if (ent.tripUpdate && ent.tripUpdate.trip && ent.tripUpdate.stopTimeUpdate) {
            const rtTripId = ent.tripUpdate.trip.tripId;
            ent.tripUpdate.stopTimeUpdate.forEach(stu => {
              if (stu.stopId && (stu.departure || stu.arrival)) {
                const rtTime = stu.departure?.time ?? stu.arrival?.time ?? null;
                const rtDelay = stu.departure?.delay ?? stu.arrival?.delay ?? null;
                if (rtTime !== null) {
                  realTimeUpdates[rtTripId + '|' + stu.stopId] = { time: rtTime, delay: rtDelay };
                }
              }
            });
          }
        });
      }

      // Mise à jour de departuresRef
      departuresRef.current = departuresRef.current.map(d => {
        const key = d.trip_id + '|' + d.stop_id;
        if (realTimeUpdates[key]) {
          const { time: rtTime, delay: rtDelay } = realTimeUpdates[key];
          const normalized = normalizeTimeFromTimestamp(rtTime);
          return {
            ...d,
            departure_time: normalized,
            delay: delayToString(rtDelay)
          };
        } else {
          // S'il n'y a pas d'update, on garde la departure_time en l'état
          return {
            ...d,
            delay: "Temps théorique"
          };
        }
      });

      // Mise à jour de l'affichage direct dans la liste
      for (let d of departuresRef.current) {
        const key = d.trip_id + '|' + d.stop_id;
        const refs = domRefs.current[key];
        if (refs) {
          const { timeEl, unitEl, delayEl, iconEl } = refs;
          const { topValue, bottomValue } = getDisplayTime(d.departure_time);
          timeEl.textContent = topValue;
          unitEl.textContent = bottomValue;
          delayEl.innerHTML = d.delay; // on met innerHTML pour le bold éventuel
          iconEl.style.display = (d.delay === "Temps théorique") ? 'none' : 'inline-block';
        }
      }

      filterAndSort();
      setInitialDepartures([...departuresRef.current]);

    } catch (err) {
      console.error('Error updating with real-time data:', err);
    }
  }

  /**
   * Filtre : on supprime les départs déjà passés
   * Tri : on ordonne par le temps restant
   */
  function filterAndSort() {
    // On retire les départs passés
    departuresRef.current = departuresRef.current.filter(d => {
      return getTimeDifferenceInMinutes(d.departure_time) >= 0;
    });
    // Tri
    departuresRef.current.sort((a, b) => {
      return getTimeDifferenceInMinutes(a.departure_time) - getTimeDifferenceInMinutes(b.departure_time);
    });
  }

  // Conversion du délai (en secondes) en chaîne user-friendly
  function delayToString(delay) {
    if (delay === null) return "Temps théorique";
    if (Math.abs(delay) < 60) return "À l'heure";
    const delayMin = Math.round(delay / 60);
    if (delayMin > 0) return `Retardé de <strong>${delayMin} min</strong>`;
    return `En avance de <strong>${Math.abs(delayMin)} min</strong>`;
  }

  // Normalise une chaîne HH:MM:SS
  function normalizeTimeStr(timeStr) {
    const [H, M, S] = timeStr.split(':').map(Number);
    return normalizeTimeFromParts(H, M, S);
  }

  // Convertit un timestamp (en secondes) en HH:MM:SS
  function normalizeTimeFromTimestamp(rtTime) {
    const rtDate = new Date(rtTime * 1000);
    const H = rtDate.getHours();
    const M = rtDate.getMinutes();
    const S = rtDate.getSeconds();
    return normalizeTimeFromParts(H, M, S);
  }

  // Construit une chaîne HH:MM:SS en 24h (over 24 → mod 24)
  function normalizeTimeFromParts(H, M, S) {
    const hours = H % 24;
    const HH = String(hours).padStart(2, '0');
    const MM = String(M).padStart(2, '0');
    const SS = String(S).padStart(2, '0');
    return `${HH}:${MM}:${SS}`;
  }

  // Choisit couleur de texte en fonction de la couleur de fond (route_color)
  function getContrastColor(hexColor) {
    let color = hexColor.replace('#', '');
    if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
    }
    if (color.length !== 6) {
      return '#000000';
    }
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  // Retourne le nombre de minutes entre maintenant et departureTime
  function getTimeDifferenceInMinutes(departureTimeStr) {
    const [H, M, S] = departureTimeStr.split(':').map(Number);
    const now = new Date();
    const depDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), H, M, S);
    const diffMs = depDate - now;
    return Math.round(diffMs / 60000); // ms -> minutes
  }

  // Renvoie l'affichage (haut: minutes ou heure ; bas: 'MIN' ou vide)
  function getDisplayTime(departureTime) {
    const diffMinutes = getTimeDifferenceInMinutes(departureTime);
    if (diffMinutes < 1 && diffMinutes >= 0) {
      return { topValue: '<1', bottomValue: 'MIN' };
    } else if (diffMinutes < 60 && diffMinutes >= 0) {
      return { topValue: `${diffMinutes}`, bottomValue: 'MIN' };
    } else if (diffMinutes >= 60) {
      const [HH, MM] = departureTime.split(':'); 
      return { topValue: `${HH}:${MM}`, bottomValue: '' };
    } else {
      return { topValue: '', bottomValue: '' };
    }
  }

  // Si pas de nom d'arrêt, on affiche "non disponible"
  const displayedStopName = stopName !== null ? stopName : "Nom de l'arrêt non disponible";
  // On affiche la ville entre parenthèses si cityName est non nul
  const displayedStopNameWithCity = cityName ? `${displayedStopName} (${cityName})` : displayedStopName;

  return (
    <Container style={{ marginTop: '5px' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {initialDepartures === null ? (
          // Placeholder de chargement
          <div className="placeholder-glow" style={{ marginBottom: '10px' }}>
            <div
              className="placeholder col-4"
              style={{
                margin: '0 auto',
                fontSize: '1.5rem',
                height: '1.5em',
                borderRadius: '4px'
              }}
            />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px'
                }}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                  }}
                  style={{
                    color: '#0A78A4',
                    fontSize: '2.5rem',
                    textDecoration: 'none'
                  }}
                >
                  <IoCaretBackCircleOutline />
                </a>
                <div
                  style={{
                    fontSize: '0.8rem',
                    color: '#333',
                    textAlign: 'right'
                  }}
                >
                  {cityName}
                </div>
              </div>
              <h2
                style={{
                  fontWeight: 'normal',
                  margin: '0',
                  color: '#0A78A4'
                }}
              >
                {displayedStopName}
              </h2>
            </div>
            <hr />
          </>
        )}
      </div>

      {initialDepartures === null ? (
        // Spinner de chargement
        <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
          <div
            className="spinner-border"
            style={{ color: '#0A78A4' }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : initialDepartures.length > 0 ? (
        // Liste des départs
        <TransitionGroup component={ListGroup} ref={listGroupRef}>
          {initialDepartures.map((dep) => {
            const bgColor =
              dep.route_color && dep.route_color.length === 6
                ? `#${dep.route_color}`
                : '#cccccc';
            const textColor = getContrastColor(bgColor);

            const { topValue, bottomValue } = getDisplayTime(dep.departure_time);
            const key = dep.trip_id + '|' + dep.stop_id;
            const timeClass = topValue === '<1' ? 'blink dep-time' : 'dep-time';

            // Référence DOM pour mise à jour
            const setRef = (el) => {
              if (el) {
                const timeEl = el.querySelector('.dep-time');
                const unitEl = el.querySelector('.dep-unit');
                const delayEl = el.querySelector('.dep-delay');
                const iconEl = el.querySelector('.dep-icon');

                domRefs.current[key] = {
                  timeEl,
                  unitEl,
                  delayEl,
                  iconEl,
                  itemEl: el
                };

                // Texte initial
                timeEl.textContent = topValue;
                unitEl.textContent = bottomValue;
                delayEl.innerHTML = dep.delay || "Temps théorique";
                iconEl.style.display =
                  dep.delay === "Temps théorique" || !dep.delay
                    ? 'none'
                    : 'inline-block';
              }
            };

            return (
              <CSSTransition key={key} classNames="fade" timeout={500}>
                <ListGroup.Item
                  ref={setRef}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: bgColor,
                    color: textColor,
                    borderColor: '#ffffff',
                    padding: '5px'
                  }}
                >
                  <svg
                    className="dep-icon"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      transform: 'rotate(90deg)'
                    }}
                    fill={textColor}
                  >
                    <g>
                    <path
                            d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                            style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                          />
                          <path
                            d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                            style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                          />    </g>
                  </svg>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                      {dep.route_short_name}
                    </div>
                    <div style={{ fontSize: '0.8rem' }}>
                      {dep.direction_name}
                    </div>
                    <div className="dep-delay" style={{ fontSize: '0.8rem' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      className={timeClass}
                      style={{
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    />
                    <div
                      className="dep-unit"
                      style={{ fontSize: '0.8rem', textAlign: 'center' }}
                    />
                  </div>
                </ListGroup.Item>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      ) : (
        // Pas de départ à venir
        <div
          style={{
            backgroundColor: '#CC4F39',
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '10px'
          }}
        >
          {stopName !== null ? "Aucun départ à venir." : "Nom de l'arrêt non disponible."}
        </div>
      )}
    </Container>
  );
}

export default StopDepartures;
