// Schedule.js

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Form,
  FormControl,
  Spinner,
  Row,
  Col,
  Placeholder,
  Toast,
  ToastContainer
} from 'react-bootstrap';

import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  useMap,
  Popup
} from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Helmet } from 'react-helmet';
import protobuf from 'protobufjs';

// Icons
import { TbArrowBackUp, TbGpsFilled } from "react-icons/tb";
import {
  FaCaretRight,
  FaCaretLeft,
  FaBusSimple,
  FaRegStar,
  FaStar,
  FaCircleCheck
} from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdChangeCircle, MdTram } from "react-icons/md";
import { BsFillMoonStarsFill } from "react-icons/bs";
import { IoMdSubway, IoIosBoat } from "react-icons/io";

import './App.css'; // Contient éventuellement l'animation "Imminent", etc.

// Configuration de l’icône par défaut leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Intervalle de rafraîchissement (ms) pour le temps réel
const REALTIME_REFRESH_INTERVAL = 8000;

/** Détecte si une couleur hex est "foncée" (pour texte blanc/noir) */
const isColorDark = (color) => {
  if (!color) return false;
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma < 128;
};

/**
 * Icône de véhicule temps réel pour Leaflet
 * On reçoit un paramètre vehicleType (1=bus, 2=métro, 3=tram, 4=bateau)
 */
const createVehicleIcon = (color, _textColor, bearing, opacity = 1, vehicleType = 1) => {
  const textColor = isColorDark(color) ? 'white' : 'black';

  // Choix de l’icône en fonction du type
  let vehicleIcon = null;
  switch (vehicleType) {
    case 2:
      vehicleIcon = <IoMdSubway style={{ color: textColor, fontSize: '20px' }} />;
      break;
    case 3:
      vehicleIcon = <MdTram style={{ color: textColor, fontSize: '20px' }} />;
      break;
    case 4:
      vehicleIcon = <IoIosBoat style={{ color: textColor, fontSize: '20px' }} />;
      break;
    case 1:
    default:
      vehicleIcon = <FaBusSimple style={{ color: textColor, fontSize: '20px' }} />;
      break;
  }

  const html = renderToStaticMarkup(
    <div
      className="vehicle-icon"
      style={{
        zIndex: 1000,
        backgroundColor: color,
        transform: `rotate(${bearing}deg)`,
        opacity
      }}
    >
      {/* Grand cercle flou derrière */}
      <div
        className="circle-background"
        style={{
          backgroundColor: color,
          opacity: 0.3,
          position: 'absolute',
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
      {vehicleIcon}

      {/* Flèche */}
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderBottom: `30px solid ${color}`,
          position: 'absolute',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      />
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: `12px solid ${textColor}`,
          position: 'absolute',
          top: '-8px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      />
    </div>
  );

  return L.divIcon({
    className: '',
    html,
    iconSize: [35, 35]
  });
};

const getCurrentLocalDate = () => {
  const now = new Date();
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const localTimeMs = now.getTime() - offsetMs;
  const localDate = new Date(localTimeMs);
  return localDate.toISOString().split('T')[0]; // YYYY-MM-DD
};

/** Vérifie si la date est le jour actuel */
const isToday = (date) => date === new Date().toISOString().split('T')[0];

/** Convertit un timestamp (en secondes) en HH:MM */
const convertTimestampToTime = (timestamp) => {
  const d = new Date(timestamp * 1000);
  const hh = String(d.getHours()).padStart(2, '0');
  const mm = String(d.getMinutes()).padStart(2, '0');
  return `${hh}:${mm}`;
};

/**
 * Calcule le temps restant en secondes jusqu'à un timestamp donné.
 * @param {number} epoch - Timestamp en secondes.
 * @returns {number} - Temps restant en secondes (minimum 0).
 */
const getSecondsRemaining = (epoch) => {
  const nowMs = Date.now();
  const arrMs = epoch * 1000;
  let diffSec = Math.round((arrMs - nowMs) / 1000);
  return diffSec > 0 ? diffSec : 0;
};

/* ------------------------------------------------------------------
   COMPOSANT : AnimatedVehicleMarker
   Il gère l’animation (glissement) depuis l'ancienne position
   vers la nouvelle, sur 1 seconde.
------------------------------------------------------------------ */
function AnimatedVehicleMarker({
  veh,
  routeInfo,
  routeColor,
  selectedVehicleId,
  setSelectedVehicleId,
  vehicleMarkerRefs,
  vehiclesData,
  realTimeSchedules,
  organizedSchedules,
  createVehicleIcon,
  haversineDistanceMeters,
  formatDistance,
  getTimeRemainingText
}) {
  const { networkId } = useParams(); // Pour savoir si c'est TOHM/CAP/CLT_LB ou pas

  // Position "animée" (interpolation)
  const [currentPos, setCurrentPos] = useState({
    lat: veh.position.latitude,
    lng: veh.position.longitude
  });

  // Anime la transition de l’ancienne à la nouvelle position (1 seconde)
  useEffect(() => {
    const start = Date.now();
    const duration = 1000; // 1 seconde
    const oldLat = currentPos.lat;
    const oldLng = currentPos.lng;
    const newLat = veh.position.latitude;
    const newLng = veh.position.longitude;

    function animate() {
      const now = Date.now();
      const elapsed = now - start;
      if (elapsed < duration) {
        const fraction = elapsed / duration;
        const lat = oldLat + (newLat - oldLat) * fraction;
        const lng = oldLng + (newLng - oldLng) * fraction;
        setCurrentPos({ lat, lng });
        requestAnimationFrame(animate);
      } else {
        setCurrentPos({ lat: newLat, lng: newLng });
      }
    }
    requestAnimationFrame(animate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veh.position.latitude, veh.position.longitude]);

  // Le "prochain arrêt" si on a veh.stop_id (ou stop_sequence)
  const nxtStop = organizedSchedules[veh.tripId]?.find(s => s.stop_id === veh.stop_id);
  const nxtName = nxtStop ? nxtStop.stop_name : '';
  const dist = nxtStop
    ? haversineDistanceMeters(
        [veh.position.latitude, veh.position.longitude],
        [parseFloat(nxtStop.stop_lat), parseFloat(nxtStop.stop_lon)]
      )
    : 0;

  // Récupération du TRIP (liste d’arrêts)
  const trip = organizedSchedules[veh.tripId];
  let termName = '';
  let termRT = null;

  if (trip && trip.length > 0) {
    const lastStopObj = trip[trip.length - 1];
    termName = lastStopObj.stop_name;

    // Pour TOHM/CAP/CLT_LB, on indexe par stop_sequence dans la RT
    const lastStopKey = ["TOHM", "CAP", "CLT_LB"].includes(networkId)
      ? lastStopObj.stop_sequence
      : lastStopObj.stop_id;

    const realTrip = realTimeSchedules[veh.tripId] || {};
    termRT = lastStopKey ? realTrip[lastStopKey] : null;
  }

  // Calcule le time remaining
  let terminusTimeRemaining = '';
  if (termRT?.epochTime) {
    terminusTimeRemaining = getTimeRemainingText(termRT.epochTime);
  }

  // Données BDD véhicule
  const vInfo = vehiclesData[veh.id];
  const vehicleType = vInfo?.type ?? 1; // par défaut 1 = bus

  // Rendu du marqueur
  return (
    <Marker
      position={[currentPos.lat, currentPos.lng]}
      icon={createVehicleIcon(
        routeColor,
        'white',
        veh.bearing,
        veh.opacity,
        vehicleType
      )}
      zIndexOffset={1000}
      ref={mk => {
        if (mk) vehicleMarkerRefs.current[veh.id] = mk;
      }}
      eventHandlers={{
        click: () => setSelectedVehicleId(veh.id),
        popupclose: () => {
          if (veh.id === selectedVehicleId) {
            setSelectedVehicleId(null);
          }
        }
      }}
    >
      <Popup maxWidth={200} minWidth={200} autoPan={false} interactive>
        <div
          style={{
            position: 'relative',
            width: '100%',
            textAlign: 'center',
            padding: '10px'
          }}
        >
          {/* Bulle avec le nom de la ligne */}
          <div
            style={{
              position: 'absolute',
              top: '-40px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              color: 'black',
              backgroundColor: '#fff',
              padding: '5px 15px',
              borderRadius: '10px',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
              fontSize: '16px',
              border: `3px solid ${routeColor}`
            }}
          >
            {routeInfo && routeInfo.route_short_name}
            <div
              style={{
                content: '""',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '-10px',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: `10px solid ${routeColor}`
              }}
            />
          </div>

          {nxtName ? (
            <>
              <div
                style={{
                  marginBottom: '5px',
                  fontSize: '16px',
                  color: '#333',
                  lineHeight: '1.5',
                  marginTop: '0px'
                }}
              >
                <strong>Prochain arrêt :</strong> <br />
                <span style={{ color: '#444' }}>
                  {nxtName.toUpperCase()}
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#222',
                    marginTop: '2px'
                  }}
                >
                  {dist > 0 ? `à ${formatDistance(dist)}` : ''}
                </span>
              </div>

              <hr
                style={{
                  border: 'none',
                  borderTop: '1px solid #ccc',
                  margin: '10px 0'
                }}
              />
            </>
          ) : (
            <div
              style={{
                color: '#666',
                textAlign: 'center',
                marginBottom: '10px',
                fontSize: '14px'
              }}
            >
              Ce véhicule ne dispose pas de donnée en temps réel pour le prochain arrêt
            </div>
          )}

          {termName && (
            <div
              style={{
                fontSize: '14px',
                color: '#555',
                lineHeight: '1.5',
                marginBottom: '10px'
              }}
            >
              {(() => {
                // Cas où l'on n'a pas d'epochTime => on affiche "Terminus : XXX"
                if (!termRT?.epochTime) {
                  return (
                    <>
                      Terminus : <b>{termName}</b>
                    </>
                  );
                }
                // Sinon, on calcule le temps restant
                const termSecRemaining = getSecondsRemaining(termRT.epochTime);
                if (termSecRemaining < 60) {
                  return (
                    <>
                      Arrivée à <br />
                      <b style={{ color: '#222' }}>{termName}</b>
                      <br />
                      <div className="imminent-text">
                        Imminent
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      Arrivée à <br />
                      <b style={{ color: '#222' }}>{termName}</b>
                      <br />
                      dans <b>{terminusTimeRemaining.replace(/[()]/g, '')}</b>
                      <svg 
                        width="1em" 
                        height="1em" 
                        viewBox="0 0 20 20" 
                        style={{ transform: 'rotate(130deg)', marginLeft: '5px' }}
                      >
                        <g fill="#FBAC2A">
                          <path
                            d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                            style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                          />
                          <path
                            d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                            style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                          />
                        </g>
                      </svg>
                                        </>
                                      );
                                    }
                                  })()}
                                </div>
                              )}
                    
                              <hr
                                style={{
                                  border: 'none',
                                  borderTop: '1px solid #ccc',
                                  margin: '10px 0'
                                }}
                              />
                    
                              {/* Si on a des infos du véhicule en BDD */}
                              {vInfo && vInfo.vehicle_id ? (
                                <div style={{ marginBottom: '-10px', color: '#333', lineHeight: '1.5' }}>
                                  <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '3px' }}>
                                    {vInfo.brand}
                                  </div>
                                  <div style={{ fontSize: '14px', color: '#555' }}>
                                    {vInfo.model}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginBottom: '5px',
                                    fontSize: '16px',
                                    color: '#333',
                                    lineHeight: '1.5'
                                  }}
                                >
                                  <strong>Véhicule ID :</strong> {veh.id}
                                </div>
                              )}
                            </div>
                          </Popup>
                        </Marker>
  );
}

/* ------------------------------------------------------------------
   COMPOSANT PRINCIPAL : Schedule
------------------------------------------------------------------ */
function Schedule() {
  const { networkId, routeId, directionId } = useParams();
  const navigate = useNavigate();
  const mapRef = useRef(null);

  // -------------------- STATES --------------------
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const vehicleMarkerRefs = useRef({});

  // Horaires statiques
  const [schedules, setSchedules] = useState([]);
  const [organizedSchedules, setOrganizedSchedules] = useState({});
  const [tripIds, setTripIds] = useState([]);

  // Infos route
  const [routeInfo, setRouteInfo] = useState(null);

  // Sélections
  const [selectedDate, setSelectedDate] = useState(getCurrentLocalDate());
  const [selectedTime, setSelectedTime] = useState(
    new Date().toTimeString().split(' ')[0].substring(0, 5)
  );
  const [currentTripIndex, setCurrentTripIndex] = useState(0);
  const [showMap, setShowMap] = useState(false);

  // Arrêts + shape
  const [stops, setStops] = useState([]);
  const [shapePoints, setShapePoints] = useState([]);

  // Couleur de la ligne
  const [routeColor, setRouteColor] = useState('#000000');

  // Directions
  const [directions, setDirections] = useState([]);

  // Temps réel
  const [realTimeVehicles, setRealTimeVehicles] = useState([]);
  const [realTimeSchedules, setRealTimeSchedules] = useState({});

  // Divers UI
  const [shouldCenterMap, setShouldCenterMap] = useState(true);
  const [displayText, setDisplayText] = useState("géolocalisation");
  const [loadingRouteInfo, setLoadingRouteInfo] = useState(true);
  const [loadingShape, setLoadingShape] = useState(true);
  const [loadingSchedules, setLoadingSchedules] = useState(true);

  // Favoris
  const [isFavorite, setIsFavorite] = useState(false);

  // Infos véhicule (marque, type, etc.)
  const [vehiclesData, setVehiclesData] = useState({});

  // Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  // Villes (Mapbox)
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  // Contrôle auto-update du tripIndex
  const [autoUpdateTripIndex, setAutoUpdateTripIndex] = useState(true);

  // Pour clean up plus tard
  const intervalRef = useRef(null);
  const abortControllerVehiclesRef = useRef(null);
  const abortControllerSchedulesRef = useRef(null);

  // -------------------- useEffect Favoris --------------------
  useEffect(() => {
    const favorites = getFavoritesFromCookie();
    const isFav = favorites.some(
      (f) => f.network_id === networkId && f.route_id === routeId
    );
    setIsFavorite(isFav);
  }, [networkId, routeId]);

  const getFavoritesFromCookie = () => {
    const name = 'favorites=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let favorites = [];
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        favorites = JSON.parse(c.substring(name.length, c.length));
        break;
      }
    }
    return Array.isArray(favorites) ? favorites : [];
  };

  const setFavoritesToCookie = (favArr) => {
    const now = new Date();
    now.setTime(now.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + now.toUTCString();
    document.cookie = "favorites=" + JSON.stringify(favArr) + ";" + expires + ";path=/";
  };

  const toggleFavorite = () => {
    const favorites = getFavoritesFromCookie();
    const index = favorites.findIndex(
      (f) => f.network_id === networkId && f.route_id === routeId
    );

    if (index !== -1) {
      // Retirer
      favorites.splice(index, 1);
      setFavoritesToCookie(favorites);
      setIsFavorite(false);
      setToastMessage(
        <>La ligne <strong>{routeInfo?.route_short_name}</strong> a bien été retirée de vos favoris</>
      );
      setShowToast(true);
    } else {
      // Ajouter
      if (routeInfo) {
        const newFav = {
          network_id: networkId,
          route_id: routeId,
          route_short_name: routeInfo.route_short_name,
          route_long_name: routeInfo.route_long_name,
          route_color: routeInfo.route_color
        };
        favorites.push(newFav);
        setFavoritesToCookie(favorites);
        setIsFavorite(true);

        setToastMessage(
          <>La ligne <strong>{routeInfo.route_short_name}</strong> a bien été ajoutée à vos favoris</>
        );
        setShowToast(true);
      }
    }
  };

  // -------------------- useEffect rotation texte bouton carte --------------------
  useEffect(() => {
    const texts = ["géolocalisation", "plan intéractif"];
    let idx = 0;
    const itv = setInterval(() => {
      idx = (idx + 1) % texts.length;
      setDisplayText(texts[idx]);
    }, 5000);
    return () => clearInterval(itv);
  }, []);

  // -------------------- useEffect fetch init --------------------
  useEffect(() => {
    fetchDirections();
    fetchSchedules(selectedDate, selectedTime);
    fetchRouteInfo();
    fetchStops();

    return () => {
      if (abortControllerVehiclesRef.current) {
        abortControllerVehiclesRef.current.abort();
      }
      if (abortControllerSchedulesRef.current) {
        abortControllerSchedulesRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkId, routeId, directionId, selectedDate, selectedTime]);

  // -------------------- useEffect shapePoints --------------------
  useEffect(() => {
    if (tripIds.length > 0) {
      fetchShapePoints(tripIds[currentTripIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTripIndex, tripIds]);

  // ----------------------------------------------------------------
  // NOUVELLE FONCTION : pour récupérer l'état annulé/manuel depuis BDD
  // ----------------------------------------------------------------
  const fetchManualCancellations = async () => {
    try {
      // Exemple : un endpoint qui renvoie un JSON
      // [{ "trip_id": "12345", "canceled": true, "canceled_reason": "..." }, ...]
      const resp = await axios.get(
        `/getManualCancellations.php?date=${selectedDate}&route_id=${routeId}&direction_id=${directionId}`
      );
      if (resp.data && Array.isArray(resp.data)) {
        // On clone l'objet existant
        const updated = { ...organizedSchedules };

        resp.data.forEach(item => {
          const tid = item.trip_id;
          const canceled = !!item.canceled; // cast en bool
          const reason = item.canceled_reason || '';

          if (updated[tid]) {
            // On marque tous les arrêts du trip comme annulés
            updated[tid].forEach(stop => {
              stop.canceled = canceled;
              stop.canceled_reason = canceled ? reason : '';
            });
          }
        });

        setOrganizedSchedules(updated);
      }
    } catch (err) {
      console.error("Erreur fetchManualCancellations:", err);
    }
  };

  // -------------------- useEffect temps réel --------------------
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // On ne déclenche ce refresh que si c'est "aujourd'hui"
    if (isToday(selectedDate)) {
      // On fait un premier appel
      fetchRealTimeVehicles();
      fetchRealTimeSchedules();
      fetchManualCancellations(); // AJOUT : on met à jour l’annulation manuelle

      // On met en place l'intervalle
      intervalRef.current = setInterval(() => {
        fetchRealTimeVehicles();
        fetchRealTimeSchedules();
        fetchManualCancellations(); // AJOUT : on re-check annulation manuelle
      }, REALTIME_REFRESH_INTERVAL);
    } else {
      // Pas d'intervalle => on nettoie
      setRealTimeVehicles([]);
      setRealTimeSchedules({});
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeInfo, directionId, selectedDate]);

  // -------------------- useEffect fetchCities --------------------
  useEffect(() => {
    if (stops.length > 0) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stops]);

  // -------------------- useEffect infos véhicules (brand, type, etc.) --------------------
  useEffect(() => {
    const fetchDataForVehicles = async () => {
      const newData = { ...vehiclesData };
      let changed = false;

      const MAX_VEHICLES_FETCH = 10;
      const vehiclesToFetch = realTimeVehicles.slice(0, MAX_VEHICLES_FETCH);

      for (const v of vehiclesToFetch) {
        if (!newData[v.id]) {
          try {
            const r = await axios.get(
              `/getVehicleInfo.php?vehicle_id=${encodeURIComponent(v.id)}&agence=${encodeURIComponent(networkId)}`
            );
            newData[v.id] = r.data; 
            changed = true;
          } catch (err) {
            console.error("Erreur infos véhicule:", err);
          }
        }
      }
      if (changed) {
        setVehiclesData(newData);
      }
    };

    if (realTimeVehicles.length > 0) {
      fetchDataForVehicles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeVehicles, networkId]);

  // -------------------- useEffect popup if selectedVehicleId --------------------
  useEffect(() => {
    if (selectedVehicleId && vehicleMarkerRefs.current[selectedVehicleId]) {
      vehicleMarkerRefs.current[selectedVehicleId].openPopup();
    }
  }, [selectedVehicleId]);

  // ============= FETCH data =============
  const fetchRouteInfo = () => {
    const controller = new AbortController();
    abortControllerVehiclesRef.current = controller;
    axios
      .get(`/getBusLines.php?route_info=1&route_id=${routeId}&network_id=${networkId}`, {
        signal: controller.signal
      })
      .then((resp) => {
        if (resp.data) {
          setRouteInfo(resp.data);
          setRouteColor(`#${resp.data.route_color}`);
        }
        setLoadingRouteInfo(false);
      })
      .catch(() => {
        setLoadingRouteInfo(false);
      });
  };

  const fetchDirections = () => {
    axios
      .get(`/getDirections.php?route_id=${routeId}&network_id=${networkId}`)
      .then(resp => {
        setDirections(resp.data);
      })
      .catch(() => {});
  };

  const fetchSchedules = (date, time) => {
    setLoadingSchedules(true);
    const controller = new AbortController();
    abortControllerSchedulesRef.current = controller;

    axios
      .get(`/getSchedules.php?route_id=${routeId}&direction_id=${directionId}&date=${date}&network_id=${networkId}`, {
        signal: controller.signal
      })
      .then(resp => {
        const org = {};
        resp.data.forEach(sch => {
          if (!org[sch.trip_id]) {
            org[sch.trip_id] = [];
          }
          // Convertir stop_sequence en nombre
          sch.stop_sequence = parseInt(sch.stop_sequence, 10);
          org[sch.trip_id].push(sch);
        });

        // Tri par stop_sequence si (TOHM/CAP/CLT_LB)
        if (resp.data.length > 0) {
          Object.keys(org).forEach(tid => {
            if (["TOHM", "CAP", "CLT_LB"].includes(networkId)) {
              org[tid].sort((a, b) => a.stop_sequence - b.stop_sequence);
            } else {
              // Sinon tri par heure d'arrivée
              org[tid].sort((a, b) => a.arrival_time.localeCompare(b.arrival_time));
            }
          });
        }

        setSchedules(resp.data);
        setOrganizedSchedules(org);

        // On trie les tripIds par heure d'arrivée du 1er stop
        const sortedTripIds = Object.keys(org).sort((a, b) => {
          const firstA = org[a][0]?.arrival_time || '';
          const firstB = org[b][0]?.arrival_time || '';
          return firstA.localeCompare(firstB);
        });
        setTripIds(sortedTripIds);

        // Déterminer tripIndex initial en fonction de l'heure courante
        const [hCur, mCur] = time.split(':').map(Number);
        const nowMin = hCur * 60 + mCur;

        let initIndex = sortedTripIds.findIndex(tid => {
          return org[tid].some(s => {
            const [hh, mm] = s.arrival_time.split(':').map(Number);
            return (hh * 60 + mm) >= nowMin;
          });
        });
        if (initIndex === -1) {
          initIndex = sortedTripIds.length - 1;
        }
        setCurrentTripIndex(initIndex);

        setLoadingSchedules(false);
      })
      .catch(() => {
        setLoadingSchedules(false);
      });
  };

  const fetchStops = () => {
    axios
      .get(`/getStops.php?route_id=${routeId}&direction_id=${directionId}&network_id=${networkId}`)
      .then(resp => {
        setStops(resp.data);
      })
      .catch(() => {});
  };

  const fetchShapePoints = (tripId) => {
    setLoadingShape(true);
    axios
      .get(`/getShapes.php?trip_id=${tripId}&network_id=${networkId}`)
      .then(resp => {
        const sorted = resp.data
          .map(p => [parseFloat(p.shape_pt_lat), parseFloat(p.shape_pt_lon), p.shape_pt_sequence])
          .sort((a, b) => a[2] - b[2])
          .map(x => [x[0], x[1]]);

        setShapePoints(sorted);

        // Ajuster la couleur s’il y a route_color
        if (resp.data.length > 0 && resp.data[0]?.route_color) {
          setRouteColor(`#${resp.data[0].route_color}`);
        }
        setLoadingShape(false);
      })
      .catch(() => {
        setLoadingShape(false);
      });
  };

  const fetchRealTimeVehicles = async () => {
    try {
      const controller = new AbortController();
      abortControllerVehiclesRef.current = controller;

      const agencyResp = await axios.get(`/getAgencyVehicule.php?network_id=${networkId}`, {
        signal: controller.signal
      });
      const apiUrl = agencyResp.data.gtfs_vehicule;

      const root = await protobuf.load('/protos/gtfs-realtime.proto');
      const FeedMessage = root.lookupType('transit_realtime.FeedMessage');

      const binResp = await axios.get(apiUrl, {
        responseType: 'arraybuffer',
        signal: controller.signal
      });
      const feed = FeedMessage.decode(new Uint8Array(binResp.data));
      const nowSec = Math.floor(Date.now() / 1000);

      const filtered = feed.entity
        .filter(ent =>
          ent.vehicle &&
          ent.vehicle.trip &&
          ent.vehicle.trip.routeId === routeId &&
          ent.vehicle.trip.directionId === parseInt(directionId, 10)
        )
        .map(ent => {
          const v = ent.vehicle;
          const ts = parseInt(v.timestamp, 10);
          const diffSec = nowSec - ts;
          if (diffSec > 100) {
            return null;
          }
          let opacity = 1.0;
          if (diffSec >= 40 && diffSec <= 100) {
            opacity = 0.5;
          }

          return {
            id: v.vehicle.id,
            tripId: v.trip.tripId,
            position: v.position,
            bearing: v.position.bearing,
            routeId: v.trip.routeId,
            routeColor: routeInfo ? `#${routeInfo.route_color}` : '#000',
            stop_id: v.stopId,
            opacity,
            timestamp: ts
          };
        })
        .filter(Boolean);

      setRealTimeVehicles(filtered);
    } catch (err) {
      console.error("Erreur fetchRealTimeVehicles:", err);
    }
  };

  const fetchRealTimeSchedules = async () => {
    try {
      const controller = new AbortController();
      abortControllerSchedulesRef.current = controller;

      const infoResp = await axios.get(`/getAgencyInfo.php?network_id=${networkId}`, {
        signal: controller.signal
      });
      if (!infoResp.data || !infoResp.data.gtfs_horaires) {
        console.log("Pas de flux RT horaires => on stop.");
        return;
      }
      const apiUrl = infoResp.data.gtfs_horaires;

      const root = await protobuf.load('/protos/gtfs-realtime.proto');
      const FeedMessage = root.lookupType('transit_realtime.FeedMessage');

      const binResp = await axios.get(apiUrl, {
        responseType: 'arraybuffer',
        signal: controller.signal
      });
      const feed = FeedMessage.decode(new Uint8Array(binResp.data));

      const newRT = {};

      feed.entity.forEach(ent => {
        if (ent.tripUpdate && ent.tripUpdate.trip) {
          const tId = ent.tripUpdate.trip.tripId;
          const rel = ent.tripUpdate.trip.scheduleRelationship;
          const isCanceled = (rel === 3 || rel === 'CANCELED');

          if (!newRT[tId]) {
            newRT[tId] = {};
          }
          if (isCanceled) {
            newRT[tId].canceled = true;
          }

          if (ent.tripUpdate.stopTimeUpdate) {
            ent.tripUpdate.stopTimeUpdate.forEach((update) => {
              // Pour TOHM/CAP/CLT_LB => indexation par stopSequence
              if (["TOHM", "CAP", "CLT_LB"].includes(networkId)) {
                const stSeq = update.stopSequence;
                if (!newRT[tId][stSeq]) {
                  newRT[tId][stSeq] = {};
                }
                const schedRel = update.schedule_relationship || update.scheduleRelationship || '';
                const isSkipped = (schedRel === 'SKIPPED' || schedRel === 2);
                if (isSkipped) {
                  newRT[tId][stSeq].skipped = true;
                }

                const time = update.departure?.time || update.arrival?.time;
                if (time) {
                  const epochTime = parseInt(time, 10);
                  const formTime = convertTimestampToTime(epochTime);

                  const delay = update.arrival?.delay ?? update.departure?.delay;
                  let delayInfo = "";
                  if (typeof delay === 'number') {
                    const delayMin = Math.round(delay / 60);
                    if (delayMin > 0) {
                      delayInfo = `Retardé de ${delayMin} min`;
                    } else if (delayMin < 0) {
                      delayInfo = `En avance de ${Math.abs(delayMin)} min`;
                    } else {
                      delayInfo = `À l'heure`;
                    }
                  }

                  newRT[tId][stSeq] = {
                    ...newRT[tId][stSeq],
                    epochTime,
                    time: formTime,
                    delayInfo
                  };
                }
              } else {
                // Sinon => indexation par stopId
                const stopId = update.stopId;
                if (!newRT[tId][stopId]) {
                  newRT[tId][stopId] = {};
                }
                const schedRel = update.schedule_relationship || update.scheduleRelationship || '';
                const isSkipped = (schedRel === 'SKIPPED' || schedRel === 2);
                if (isSkipped) {
                  newRT[tId][stopId].skipped = true;
                }

                const time = update.departure?.time || update.arrival?.time;
                if (time) {
                  const epochTime = parseInt(time, 10);
                  const formTime = convertTimestampToTime(epochTime);

                  const delay = update.arrival?.delay ?? update.departure?.delay;
                  let delayInfo = "";
                  if (typeof delay === 'number') {
                    const delayMin = Math.round(delay / 60);
                    if (delayMin > 0) {
                      delayInfo = `Retardé de ${delayMin} min`;
                    } else if (delayMin < 0) {
                      delayInfo = `En avance de ${Math.abs(delayMin)} min`;
                    } else {
                      delayInfo = `À l'heure`;
                    }
                  }

                  newRT[tId][stopId] = {
                    ...newRT[tId][stopId],
                    epochTime,
                    time: formTime,
                    delayInfo
                  };
                }
              }
            });
          }
        }
      });

      setRealTimeSchedules(newRT);

      // auto-update tripIndex (si l'utilisateur n'a pas forcé un trip)
      if (tripIds.length > 0 && Object.keys(organizedSchedules).length > 0 && autoUpdateTripIndex) {
        const nowMs = Date.now();
        let newIndex = null;

        for (let i = 0; i < tripIds.length; i++) {
          const tid = tripIds[i];
          const trip = organizedSchedules[tid];
          const tripRT = newRT[tid] || {};

          const isOngoing = trip.some(stop => {
            // S'il y a des arrêts "pas passés"
            const key = (["TOHM", "CAP", "CLT_LB"].includes(networkId))
              ? stop.stop_sequence
              : stop.stop_id;

            const rtInfo = tripRT[key];
            if (rtInfo && !rtInfo.skipped) {
              if (rtInfo.epochTime && (rtInfo.epochTime * 1000) >= nowMs) {
                return true;
              }
              const [hh, mm] = stop.arrival_time.split(':').map(Number);
              const tMs = new Date().setHours(hh, mm, 0, 0);
              return tMs >= nowMs;
            } else {
              const [hh, mm] = stop.arrival_time.split(':').map(Number);
              const tMs = new Date().setHours(hh, mm, 0, 0);
              return tMs >= nowMs;
            }
          });

          if (isOngoing) {
            newIndex = i;
            break;
          }
        }

        if (newIndex !== null && newIndex !== currentTripIndex) {
          setCurrentTripIndex(newIndex);
        }
      }
    } catch (err) {
      console.error("Erreur fetchRealTimeSchedules:", err);
    }
  };

  // -------------------- MAPBOX (villes) --------------------
  const MAPBOX_TOKEN = 'pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg';
  const fetchCities = async () => {
    setLoadingCities(true);
    const coords = stops.map(st => ({ lat: st.stop_lat, lon: st.stop_lon }));
    const cityList = [];

    const ccache = localStorage.getItem('citiesCache');
    let cached = ccache ? JSON.parse(ccache) : {};

    for (const c of coords) {
      const cKey = `${c.lat},${c.lon}`;
      if (cached[cKey]) {
        cityList.push(cached[cKey]);
      } else {
        try {
          const resp = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${c.lon},${c.lat}.json`,
            {
              params: {
                access_token: MAPBOX_TOKEN,
                language: 'fr'
              }
            }
          );
          let cityName = '';
          if (resp.data && resp.data.features && resp.data.features.length > 0) {
            const feat = resp.data.features[0];
            const ctx = feat.context || [];
            const cPlace = ctx.find(x => x.id.includes('place') || x.id.includes('locality'));
            if (cPlace && cPlace.text) {
              cityName = cPlace.text;
            } else if (feat.text) {
              cityName = feat.text;
            }
          }
          const cd = { latitude: c.lat, longitude: c.lon, city_name: cityName };
          cityList.push(cd);
          cached[cKey] = cd;
        } catch (err) {
          cityList.push({ latitude: c.lat, longitude: c.lon, city_name: '' });
        }
      }
    }
    localStorage.setItem('citiesCache', JSON.stringify(cached));
    setCities(cityList);
    setLoadingCities(false);
  };

  // -------------------- Utils --------------------
  const toRad = x => (x * Math.PI) / 180;
  const haversineDistanceMeters = (c1, c2) => {
    const [lat1, lon1] = c1;
    const [lat2, lon2] = c2;
    const R = 6371000;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) ** 2;
    return Math.round(R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
  };

  const findClosestCity = (lat, lon) => {
    if (loadingCities) {
      return (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={5} />
        </Placeholder>
      );
    }
    if (!Array.isArray(cities) || cities.length === 0) return '';

    let minDist = Infinity;
    let cName = '';
    cities.forEach(ct => {
      const d = haversineDistanceMeters(
        [lat, lon],
        [parseFloat(ct.latitude), parseFloat(ct.longitude)]
      );
      if (d < minDist) {
        minDist = d;
        cName = ct.city_name;
      }
    });
    return cName;
  };

  const formatArrivalTime = (time) => {
    let [hh, mm] = time.split(':');
    hh = parseInt(hh, 10) % 24;
    return `${String(hh).padStart(2, '0')}:${mm}`;
  };

  const formatDistance = (m) => {
    if (m < 1000) {
      return `${m} mètres`;
    }
    const km = m / 1000;
    const rkm = Math.floor(km * 10) / 10;
    let str = String(rkm).replace('.', ',');
    if (str.endsWith(',0')) {
      str = str.slice(0, -2);
    }
    return `${str} km`;
  };

  const getTimeRemainingText = (epoch) => {
    const nowMs = Date.now();
    const arrMs = epoch * 1000;
    let diffMin = Math.round((arrMs - nowMs) / 60000);
    if (diffMin < 0) diffMin = 0;
    return `(${diffMin} min)`;
  };

  // -------------------- Recentrage map --------------------
  useEffect(() => {
    if (showMap && mapRef.current && shouldCenterMap) {
      const map = mapRef.current;
      if (shapePoints.length > 0) {
        map.fitBounds(shapePoints);
      } else if (stops.length > 0) {
        const stB = stops.map(s => [s.stop_lat, s.stop_lon]);
        map.fitBounds(stB);
      }
      setShouldCenterMap(false);
    }
  }, [showMap, shapePoints, stops, shouldCenterMap]);

  const MapBoundsSetter = () => {
    const map = useMap();
    useEffect(() => {
      if (shapePoints.length > 0 && shouldCenterMap) {
        map.fitBounds(shapePoints);
        setShouldCenterMap(false);
      } else if (stops.length > 0 && shouldCenterMap) {
        const sb = stops.map(st => [st.stop_lat, st.stop_lon]);
        map.fitBounds(sb);
        setShouldCenterMap(false);
      }
    }, [map]);
    return null;
  };

  // -------------------- currentTrip & realTime data --------------------
  const currentTripId = tripIds[currentTripIndex];
  const currentTrip = organizedSchedules[currentTripId] || [];

  // Récupération temps réel pour ce trip
  const tripRealTimeInfo = realTimeSchedules[currentTripId] || {};

  // Détection si le flux temps réel dit que c'est annulé
  const realTimeTripCanceled = tripRealTimeInfo.canceled === true;

  // Détection si (au moins) un arrêt est annulé manuellement => canceled = true
  const anyManualCanceled = currentTrip.some(st => st.canceled === true);

  // Récupération de la raison (on prend la première non vide)
  let canceledReason = '';
  const canceledStop = currentTrip.find(st => st.canceled_reason && st.canceled_reason.trim() !== '');
  if (canceledStop) {
    canceledReason = canceledStop.canceled_reason;
  }

  // On combine => le trip est annulé si flux RT le dit (aujourd'hui),
  // OU annulation manuelle (toutes dates)
  const isTripCanceled = realTimeTripCanceled || anyManualCanceled;

  // Autres indicateurs (pour le "véhicule non connecté", etc.)
  const hasRealTimeForTrip = isToday(selectedDate) && (realTimeSchedules[currentTripId] !== undefined);
  const nowSec = Math.floor(Date.now() / 1000);
  const isTripConnected = isToday(selectedDate) && realTimeVehicles.some(v => v.tripId === currentTripId);
  const isGpsNotConnected = hasRealTimeForTrip && !isTripConnected;
  const activeVehiclesCount = realTimeVehicles.filter(v => nowSec - v.timestamp < 120).length;

  // -------------------- Handlers --------------------
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleNextTrip = () => {
    setAutoUpdateTripIndex(false);
    if (currentTripIndex < tripIds.length - 1) {
      setCurrentTripIndex(currentTripIndex + 1);
    }
  };
  const handlePrevTrip = () => {
    setAutoUpdateTripIndex(false);
    if (currentTripIndex > 0) {
      setCurrentTripIndex(currentTripIndex - 1);
    }
  };

  const handleShowMap = () => {
    setShowMap(true);
    setShouldCenterMap(true);
  };
  const handleCloseMap = () => {
    setShowMap(false);
  };

  const handleDirectionChange = (e) => {
    const newDirId = e.target.value;
    navigate(`/schedule/${networkId}/${routeId}/${newDirId}`);
  };

  // -------------------- RENDER --------------------
  return (
    <>
      <Helmet>
        <title>
          {`Bus Connect – Réseau : ${networkId} – Ligne ${routeInfo?.route_short_name}`}
        </title>
      </Helmet>

      {loadingRouteInfo ? (
        <div
          style={{
            width: '100vw',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 999
          }}
        >
          <Spinner
            animation="border"
            style={{ width: '2rem', height: '2rem', color: '#0A78A4' }}
          />
        </div>
      ) : (
        <div
          className="sticky-header"
          style={{
            position: 'sticky',
            top: '0',
            zIndex: 999,
            backgroundColor: 'white'
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '60px',
              backgroundColor: routeColor,
              borderBottomLeftRadius: '60px',
              borderBottomRightRadius: '0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              boxShadow: `${routeColor}33 0px 10px 10px`
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '15px',
                top: '35%',
                transform: 'translateY(-50%)',
                zIndex: 1001,
                color: isColorDark(routeColor) ? 'white' : 'black',
                fontSize: '45px',
                cursor: 'pointer'
              }}
              onClick={() => navigate('/')}
            >
              <TbArrowBackUp />
            </div>

            <b
              style={{ fontSize: '2em' }}
              className={`line-text ${isColorDark(routeColor) ? 'text-light' : 'text-dark'}`}
            >
              {routeInfo && routeInfo.route_short_name}
            </b>

            <div
              style={{
                position: 'absolute',
                right: '15px',
                top: '45%',
                transform: 'translateY(-50%)',
                zIndex: 1001,
                color: isColorDark(routeColor) ? 'white' : 'black',
                fontSize: '35px',
                cursor: 'pointer'
              }}
              onClick={toggleFavorite}
            >
              {isFavorite ? <FaStar /> : <FaRegStar />}
            </div>
          </div>
        </div>
      )}

      {/* BARRE DU HAUT */}
      <div
        style={{
          position: 'sticky',
          top: '60px',
          zIndex: 998,
          backgroundColor: 'white',
          marginBottom: '20px',
          paddingBottom: '2px',
          paddingTop: '20px',
          paddingLeft: '5px',
          paddingRight: '5px',
          boxShadow: `${routeColor}33 0px 10px 10px`
        }}
      >
        <Container>
          <Row className="mb-4">
            <Col xs={12} sm={8} md={6} lg={4}>
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '15px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    fontWeight: 'bold',
                    fontSize: '12px'
                  }}
                >
                  Vers :
                </span>
                <select
                  value={directionId}
                  onChange={handleDirectionChange}
                  style={{
                    width: '100%',
                    paddingLeft: '60px',
                    paddingRight: '50px',
                    height: '50px',
                    borderRadius: '20px',
                    border: '2px solid #0A78A4',
                    appearance: 'none',
                    background: '#fff',
                    color: '#333',
                    fontSize: '14px',
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                  className="text-truncate"
                >
                  {directions.map((dir, i) => (
                    <option key={i} value={dir.direction_id} className="text-truncate">
                      {dir.headsign}
                    </option>
                  ))}
                </select>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '15px',
                    transform: 'translateY(-50%)',
                    border: '2px solid white',
                    borderRadius: '50%',
                    padding: '5px',
                    backgroundColor: 'transparent'
                  }}
                >
                  <MdChangeCircle style={{ color: 'black', fontSize: '20px' }} />
                </div>
              </div>
            </Col>
          </Row>

          <Form className="mb-4">
            <Row>
              <Col>
                <FormControl
                  type="date"
                  value={selectedDate}
                  min={getCurrentLocalDate()}
                  onChange={handleDateChange}
                />
              </Col>
              <Col>
                <FormControl
                  type="time"
                  value={selectedTime}
                  onChange={handleTimeChange}
                />
              </Col>
            </Row>
          </Form>

          {loadingSchedules ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px'
              }}
            >
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', color: '#0A78A4' }} />
            </div>
          ) : (
            <>
              {tripIds.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <BsFillMoonStarsFill style={{ fontSize: '80px', color: '#0A78A4' }} />
                  <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
                    Aucun service ce jour
                  </p>
                </div>
              ) : (
                <div className="center-button mb-4">
                  <button
                    onClick={handlePrevTrip}
                    disabled={currentTripIndex === 0}
                    className={`custom-button ${currentTripIndex === 0 ? 'disabled-button' : ''}`}
                    style={{ backgroundColor: routeColor, borderRadius: '50%', padding: '12px' }}
                  >
                    <FaCaretLeft
                      className={`custom-icon2 ${
                        currentTripIndex === 0 ? 'disabled-button' : ''
                      } ${isColorDark(routeColor) ? 'text-light' : 'text-dark'}`}
                      style={{ fontSize: '26px' }}
                      aria-label="Précédent"
                    />
                  </button>

                  <div
                    onClick={handleShowMap}
                    className="full-width-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      paddingLeft: '20px',
                      borderRadius: '100px',
                      cursor: 'pointer',
                      height: '50px',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        left: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <TbGpsFilled className="blinking-icon-gps" style={{ fontSize: '26px' }} />
                    </div>
                    {displayText}

                    {activeVehiclesCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-6px',
                          right: '-6px',
                          backgroundColor: routeColor,
                          borderRadius: '20%',
                          width: '40px',
                          height: '25px',
                          display: 'flex',
                          padding: '1px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: isColorDark(routeColor) ? 'white' : 'black',
                          border: '2px solid white'
                        }}
                      >
                        {activeVehiclesCount}
                        <FaBusSimple
                          style={{
                            marginLeft: '4px',
                            color: isColorDark(routeColor) ? 'white' : 'black'
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <button
                    onClick={handleNextTrip}
                    disabled={currentTripIndex === tripIds.length - 1}
                    className={`custom-button ${
                      currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''
                    }`}
                    style={{ backgroundColor: routeColor, borderRadius: '50%', padding: '12px' }}
                  >
                    <FaCaretRight
                      className={`custom-icon2 ${
                        currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''
                      } ${isColorDark(routeColor) ? 'text-light' : 'text-dark'}`}
                      style={{ fontSize: '26px' }}
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </Container>
      </div>

      {/* CONTENU PRINCIPAL : arrêts */}
      <Container>
        {(!loadingSchedules && tripIds.length > 0) && (
          <>
            {/* Bannière TRIP annulé */}
            {isTripCanceled && (
              <div
                style={{
                  backgroundColor: '#CC4F39',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',      // Pour centrer le texte horizontalement
                  padding: '10px',
                  marginBottom: '20px',
                  fontSize: '18px',
                  position: 'relative',     // Nécessaire pour positionner l'icône
                }}
              >
                {/* Icône positionnée à gauche */}
                <TiDelete
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    fontSize: '24px',
                  }}
                />

                {/* Texte principal au centre */}
                <span style={{ textTransform: 'uppercase', fontSize: '14px' }}>
                  Ce trajet est supprimé
                </span>

                {/* Raison de l’annulation, si présente */}
                {canceledReason && (
                  <div style={{ fontSize: '10px', marginTop: '5px' }}>
                    Raison : {canceledReason}
                  </div>
                )}
              </div>
            )}

            {/* Bannière si GPS pas connecté */}
            {!isTripCanceled && isGpsNotConnected && (
              <div
                style={{
                  backgroundColor: 'grey',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  gap: '5px',
                  fontSize: '18px'
                }}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  style={{ marginLeft: '5px', opacity: 0.3, transform: 'rotate(320deg)' }}
                  fill="currentColor"
                >
                  <path d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685" />
                  <path d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z" />
                </svg>
                <span style={{ margin: '0 auto', textTransform: 'uppercase', fontSize: '14px' }}>
                  Véhicule non connecté
                </span>
              </div>
            )}

            {/* Bannière si GPS connecté */}
            {!isTripCanceled && !isGpsNotConnected && isToday(selectedDate) && isTripConnected && (
              <div
                style={{
                  backgroundColor: '#008004',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  gap: '5px',
                  fontSize: '18px'
                }}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  style={{ transform: 'rotate(320deg)', marginLeft: '5px' }}
                >
                  <g fill="#ffffff">
                          <path
                            d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                            style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                          />
                          <path
                            d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                            style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                          />
                        </g>
                </svg>
                <span style={{ margin: '0 auto', textTransform: 'uppercase', fontSize: '14px' }}>
                  Véhicule connecté
                </span>
              </div>
            )}

            <div className="schedule-container">
              {currentTrip.map((sch, index) => {
                // On détermine la clé (stop_sequence ou stop_id)
                const uniqueKey = (["TOHM", "CAP", "CLT_LB"].includes(networkId))
                  ? sch.stop_sequence
                  : sch.stop_id;

                const city = findClosestCity(parseFloat(sch.stop_lat), parseFloat(sch.stop_lon));
                const isLastItem = (index === currentTrip.length - 1);

                const realTimeArr = tripRealTimeInfo[uniqueKey];
                const isSkipped = realTimeArr?.skipped === true;

                // Horaire théorique
                const theoreticalArrival = formatArrivalTime(sch.arrival_time);
                const now = new Date();
                const [thH, thM] = theoreticalArrival.split(':').map(Number);
                const schedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), thH, thM);
                const graceMs = 60 * 1000;
                const isCurrent = isToday(selectedDate) &&
                  now.getHours() === thH &&
                  now.getMinutes() === thM;
                const isPast = isToday(selectedDate) &&
                  now.getTime() > (schedTime.getTime() + graceMs) &&
                  !isCurrent;

                // Si le trip entier est annulé => on barre tout
                if (isTripCanceled) {
                  return (
                    <div
                      key={`${sch.trip_id}-${index}`}
                      className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                    >
                      <div
                        className={`dot-container ${isPast ? 'past' : ''}`}
                        style={{ '--route-color': routeColor }}
                      >
                        <div
                          className={`dot ${
                            index === 0 ? 'first' : ''
                          } ${isLastItem ? 'last' : ''} ${isPast ? 'past' : ''} ${
                            isCurrent ? 'current' : ''
                          }`}
                          style={{
                            backgroundColor:
                              (index === 0 || isLastItem)
                                ? (isPast ? '#d1d1d1' : 'white')
                                : 'white',
                            border:
                              (index === 0 || isLastItem)
                                ? `6px solid ${isPast ? '#d1d1d1' : routeColor}`
                                : 'none'
                          }}
                        />
                      </div>
                      <div
                        className={`info-container ${isPast ? 'past' : ''}`}
                        style={{ position: 'relative' }}
                      >
                        <div className="stop">{sch.stop_name}</div>
                        {city && <div className="stop-city">{city}</div>}
                        <div
                          className="time"
                          style={{ color: '#d70000', textDecoration: 'line-through', fontWeight: 'bold' }}
                        >
                          {theoreticalArrival}
                        </div>
                      </div>
                    </div>
                  );
                }

                // Sinon, on affiche la logique standard
                // (avec SKIPPED, etc.)
                if (isSkipped) {
                  // Arrêt non desservi
                  return (
                    <div
                      key={`${sch.trip_id}-${index}`}
                      className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                    >
                      <div
                        className={`dot-container ${isPast ? 'past' : ''}`}
                        style={{ '--route-color': routeColor }}
                      >
                        <div
                          className={`dot ${
                            index === 0 ? 'first' : ''
                          } ${isLastItem ? 'last' : ''} ${isPast ? 'past' : ''} ${
                            isCurrent ? 'current' : ''
                          }`}
                          style={{
                            backgroundColor:
                              (index === 0 || isLastItem)
                                ? (isPast ? '#d1d1d1' : 'white')
                                : 'white',
                            border:
                              (index === 0 || isLastItem)
                                ? `6px solid ${isPast ? '#d1d1d1' : routeColor}`
                                : 'none'
                          }}
                        />
                      </div>
                      <div
                        className={`info-container ${isPast ? 'past' : ''}`}
                        style={{ position: 'relative' }}
                      >
                        <div className="stop">{sch.stop_name}</div>
                        {city && <div className="stop-city">{city}</div>}
                        <div
                          className="time"
                          style={{
                            color: 'red',
                            textDecoration: 'line-through',
                            fontWeight: 'bold'
                          }}
                        >
                          {theoreticalArrival}
                        </div>
                        <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
                          (Non desservi)
                        </div>
                      </div>
                    </div>
                  );
                }

                // Arrêt normal
                const displayTime = realTimeArr?.time || theoreticalArrival;
                const [rtH, rrm] = displayTime.split(':').map(Number);
                const rtDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), rtH, rrm);

                const isCurrent2 = isToday(selectedDate) &&
                  now.getHours() === rtH &&
                  now.getMinutes() === rrm;
                const isPast2 = isToday(selectedDate) &&
                  now.getTime() > (rtDate.getTime() + graceMs) &&
                  !isCurrent2;

                const pastClass2 = isPast2 ? 'past' : '';
                const currentClass2 = isCurrent2 ? 'current' : '';

                let timeDiff = '';
                let adjustedClass = '';
                if (isToday(selectedDate) && realTimeArr && realTimeArr.time) {
                  const [rh, rm] = realTimeArr.time.split(':').map(Number);
                  const [tth, ttm] = theoreticalArrival.split(':').map(Number);
                  const diff = (rh * 60 + rm) - (tth * 60 + ttm);
                  if (diff > 0) {
                    timeDiff = `Retardé de ${diff} min`;
                    adjustedClass = 'time-late';
                  } else if (diff < 0) {
                    timeDiff = `En avance de ${Math.abs(diff)} min`;
                    adjustedClass = 'time-early';
                  } else {
                    timeDiff = `À l'heure`;
                    adjustedClass = 'time-on-time';
                  }
                }

                // Couleur (rouge/orange/vert) seulement si flux RT & véhicule connecté
                let timeColor = 'black';
                if (isToday(selectedDate) && realTimeArr && isTripConnected) {
                  const [rrh2, rrm2] = displayTime.split(':').map(Number);
                  const [tth, ttm] = theoreticalArrival.split(':').map(Number);
                  if (!Number.isNaN(rrh2) && !Number.isNaN(rrm2)) {
                    const dMin = (rrh2 * 60 + rrm2) - (tth * 60 + ttm);
                    if (dMin > 0) {
                      timeColor = '#d70000';    // retard
                    } else if (dMin < 0) {
                      timeColor = '#ea8021';    // avance
                    } else {
                      timeColor = '#008004';    // à l'heure
                    }
                  }
                }

                return (
                  <div
                    key={`${sch.trip_id}-${index}`}
                    className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                  >
                    <div
                      className={`dot-container ${pastClass2}`}
                      style={{ '--route-color': routeColor }}
                    >
                      <div
                        className={`dot ${
                          index === 0 ? 'first' : ''
                        } ${isLastItem ? 'last' : ''} ${pastClass2} ${currentClass2}`}
                        style={{
                          backgroundColor:
                            (index === 0 || isLastItem)
                              ? (isPast2 ? '#d1d1d1' : 'white')
                              : (isPast2 ? '#d1d1d1' : 'white'),
                          border:
                            (index === 0 || isLastItem)
                              ? `6px solid ${isPast2 ? '#d1d1d1' : routeColor}`
                              : 'none'
                        }}
                      />
                    </div>
                    <div
                      className={`info-container ${pastClass2}`}
                      style={{ position: 'relative' }}
                    >
                      {/* Petit SVG clignotant si la data RT est dispo et pas passé */}
                      {realTimeArr && !isPast2 && isTripConnected && (
                        <span style={{ position: 'absolute', right: '9px', top: '15%' }}>
                          <svg width="1em" height="1em" viewBox="0 0 20 20" style={{ transform: 'rotate(90deg)', marginLeft: '5px' }}>
                            <g fill="#FBAC2A">
                              <path
                                d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                                style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                              />
                              <path
                                d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                                style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                              />
                            </g>
                          </svg>
                        </span>
                      )}
                      <div className="stop">{sch.stop_name}</div>
                      {city && <div className="stop-city">{city}</div>}

                      <div className="time-container">
                        {/* Horaire théorique barré si on a un horaire RT différent */}
                        {realTimeArr && realTimeArr.time && realTimeArr.time !== theoreticalArrival && (
                          <div className="time-strikethrough">{theoreticalArrival}</div>
                        )}
                        {timeDiff && timeDiff !== "À l'heure" && (
                          <div
                            className={`time-difference ${adjustedClass}`}
                            style={{ color: 'black' }}
                          >
                            {timeDiff.split(/(\d+\smin)/).map((part, pi) => (
                              <span
                                key={pi}
                                style={{
                                  fontWeight: part.match(/\d+\smin/) ? 'bold' : 'normal'
                                }}
                              >
                                {part}{' '}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>

                      <div
                        className={`time ${adjustedClass}`}
                        style={{ color: timeColor }}
                      >
                        {displayTime}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Container>

      {/* MAP OVERLAY */}
      {showMap && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            zIndex: 9998,
            background: 'white'
          }}
        >
          <div
            onClick={handleCloseMap}
            style={{
              position: 'absolute',
              top: '15px',
              right: '25px',
              zIndex: 10000,
              cursor: 'pointer'
            }}
          >
            <AiFillCloseCircle
              style={{
                fontSize: '2.5rem',
                color: '#444',
                transition: 'transform 0.2s ease'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            />
          </div>

          {loadingShape ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner animation="border" variant="primary" style={{ width: '4rem', height: '4rem' }} />
            </div>
          ) : (
            <MapContainer
              className="no-flicker"
              style={{ height: '100%', width: '100%', backgroundColor: '#fff' }}
              whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
              attributionControl={false}
              preferCanvas
              zoomAnimation={false}
              fadeAnimation={false}
              markerZoomAnimation={false}
            >
              <TileLayer
                className="fade-tiles"
                url="https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg"
                attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                tileSize={512}
                zoomOffset={-1}
                updateWhenIdle
                updateWhenZooming={false}
              />
              <MapBoundsSetter />

              {/* TRACE DE L’ITINÉRAIRE */}
              {shapePoints.length > 0 && (
                <Polyline positions={shapePoints} color={routeColor} weight={8} />
              )}

              {/* MARQUEURS D’ARRÊTS */}
              {stops.map((stop, i3) => {
                const city = findClosestCity(parseFloat(stop.stop_lat), parseFloat(stop.stop_lon));
                return (
                  <Marker
                    key={i3}
                    position={[stop.stop_lat, stop.stop_lon]}
                    icon={L.divIcon({
                      className: 'custom-icon',
                      html: `<div style="background-color: white; border-radius: 50%; width: 18px; height: 18px; border: 2px solid ${routeColor}; z-index: 500;"></div>`
                    })}
                    zIndexOffset={-1000}
                  >
                    <Popup maxWidth={200} minWidth={200} autoPan={false} interactive>
                      <div style={{ textAlign: 'center', padding: '10px' }}>
                        <div
                          style={{
                            fontSize: '17px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold'
                          }}
                        >
                          {stop.stop_name}
                        </div>
                        {city && (
                          <div style={{ color: '#3d3d3d', fontSize: '12px' }}>
                            {city}
                          </div>
                        )}
                        <div
                          style={{
                            marginTop: '10px',
                            backgroundColor: '#ccc',
                            color: '#999',
                            padding: '8px 12px',
                            borderRadius: '20px',
                            cursor: 'not-allowed',
                            fontSize: '14px',
                            display: 'block',
                            width: '100%',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            opacity: 0.6
                          }}
                        >
                          Voir les horaires
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}

              {/* MARQUEURS DES VÉHICULES (TEMPS RÉEL) */}
              {realTimeVehicles.map((veh) => (
                <AnimatedVehicleMarker
                  key={veh.id}
                  veh={veh}
                  routeInfo={routeInfo}
                  routeColor={routeColor}
                  selectedVehicleId={selectedVehicleId}
                  setSelectedVehicleId={setSelectedVehicleId}
                  vehicleMarkerRefs={vehicleMarkerRefs}
                  vehiclesData={vehiclesData}
                  realTimeSchedules={realTimeSchedules}
                  organizedSchedules={organizedSchedules}
                  createVehicleIcon={createVehicleIcon}
                  haversineDistanceMeters={haversineDistanceMeters}
                  formatDistance={formatDistance}
                  getTimeRemainingText={getTimeRemainingText}
                />
              ))}
            </MapContainer>
          )}

          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              width: '100%',
              textAlign: 'center',
              fontSize: '14px',
              padding: '5px',
              marginLeft: '5px',
              zIndex: 10000,
              color: 'black',
              opacity: 0.2
            }}
          >
            ID : {currentTripId}
          </div>
        </div>
      )}

      {/* TOAST favoris */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 9999999, position: 'fixed', top: '20px', right: '20px' }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{ backgroundColor: 'white', border: '1px solid #ddd' }}
        >
          <Toast.Header>
            <FaCircleCheck style={{ color: 'green', marginRight: '8px' }} />
            <strong className="me-auto">Favoris mis à jour</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default Schedule;
