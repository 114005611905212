import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Container, Offcanvas, Modal, Button } from 'react-bootstrap';
import './App.css';
import logoBlanc from './assets/logo_blanc.svg';
import { FaHome } from 'react-icons/fa';
import { MdChangeCircle } from "react-icons/md";
import { TbMessage2Exclamation } from "react-icons/tb";
import { IoMenu } from "react-icons/io5";
import { FaRoute } from "react-icons/fa";
import Cookies from 'js-cookie';

// On utilise une chaîne pour la version
const MODAL_VERSION = "3.0.7";

const Header = ({ selectedNetwork, networks, showTutorial, setShowTutorial }) => {
  // --------------------------------------------------
  //               ÉTAT ET LOGIQUE DU MODAL
  // --------------------------------------------------
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    // On récupère la version de la modal vue par l'utilisateur (ou "0" si pas de cookie).
    const userModalVersion = Cookies.get('modalVersion') || "0";
    
    // Si la version vue n'est pas identique à la version actuelle, on affiche la modale.
    if (userModalVersion !== MODAL_VERSION) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    // Au clic sur "OK", on enregistre dans le cookie la version vue
    Cookies.set('modalVersion', MODAL_VERSION, { expires: 365 });
    setShowModal(false);
  };

  // --------------------------------------------------
  //              LOGIQUE OFFCANVAS / NAVBAR
  // --------------------------------------------------
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const changeNetworkRef = React.useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // On fixe la navbar si on n'est pas sur /schedule
  const isFixed = location.pathname !== '/schedule';

  // Menu "Accueil" actif si on est sur "/" ou sur "/network..."
  const isAccueilActive = location.pathname === '/' || location.pathname.startsWith('/network');

  // Récupération du nom du réseau sélectionné
  const selectedNetworkData = networks.find(net => net.network_id === selectedNetwork);
  const networkName = selectedNetworkData ? selectedNetworkData.agency_name : "";
  let mainPart = "";
  if (networkName) {
    const parts = networkName.split(" - ");
    mainPart = parts[0] ? parts[0].toUpperCase() : "";
  }

  // --------------------------------------------------
  //             LOGIQUE TUTORIEL (OVERLAY)
  // --------------------------------------------------
  const handleTutorialClose = () => {
    setShowTutorial(false);
    Cookies.set('tutorialShown', 'true', { expires: 365 });
  };

  // Calcul de la position de l'icône "changer de réseau"
  const [iconPos, setIconPos] = React.useState({ x: 0, y: 0 });
  const [iconSize, setIconSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (changeNetworkRef.current) {
      const rect = changeNetworkRef.current.getBoundingClientRect();
      setIconPos({ x: rect.left + window.scrollX, y: rect.top + window.scrollY });
      setIconSize({ width: rect.width, height: rect.height });
    }
  }, [showTutorial]);

  return (
    <>
      {/* ============================================================
          MODAL QUI S'AFFICHE À CHAQUE NOUVELLE VERSION (ex: 3.0.5)
         ============================================================ */}
   <Modal show={showModal} onHide={handleCloseModal} backdrop="static" centered>
  <Modal.Header closeButton>
    <Modal.Title>Information</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ fontSize: '0.80rem' }}>
    {/* ---- Ton nouveau message / notification ---- */}
    <p> La recherche par arrêt de bus sera réactivée prochainement. Le bug est toujours en cours de résolution. Merci pour votre patience.</p>
    <i style={{ fontSize: '0.50rem'}}>{MODAL_VERSION}</i>
    {/* ------------------------------------------- */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleCloseModal}>
      OK
    </Button>
  </Modal.Footer>
</Modal>


      {/* ============================================================
          NAVBAR
         ============================================================ */}
      <Navbar
        className={`navbar-custom ${isFixed ? 'fixed-top' : ''}`}
        expand="lg"
        style={{
          background: 'linear-gradient(135deg, #0A78A4 0%, #66A7C5 100%)',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Container style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <Navbar.Brand href="#" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logoBlanc}
              className="d-inline-block align-top"
              alt="Logo"
              style={{ height: '45px' }}
            />
          </Navbar.Brand>

          {mainPart && (
            <div
              style={{
                color: 'white',
                marginLeft: '20px',
                fontSize: '12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <div style={{ fontSize: '10px' }}>Réseau :</div>
              <div style={{ fontWeight: 'bold' }}>{mainPart}</div>
            </div>
          )}

          {mainPart && (
            <Link
              to="/change-network"
              style={{ marginLeft: '10px', color: '#fff' }}
              title="Changer de réseau"
              ref={changeNetworkRef}
            >
              <MdChangeCircle
                size={30}
                style={{
                  cursor: 'pointer',
                  transition: '0.3s',
                  marginTop: '-2px'
                }}
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              />
            </Link>
          )}

          <div style={{ marginLeft: 'auto' }}>
            <IoMenu
              size={54}
              onClick={handleShow}
              style={{
                padding: '5px 10px',
                cursor: 'pointer',
                color: '#fff'
              }}
            />
          </div>
        </Container>
      </Navbar>

      {/* ============================================================
          OFFCANVAS MENU
         ============================================================ */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Accueil */}
          <div
            className={`menu-item ${isAccueilActive ? 'active' : ''}`}
            onClick={handleClose}
          >
            <Link to="/" className="menu-link">
              <div className="menu-content">
                <div className="menu-icon">
                  <FaHome size={20} />
                </div>
                <div className="menu-text">Accueil</div>
              </div>
            </Link>
          </div>

          {/* Info Trafic */}
          <div
            className={`menu-item ${location.pathname === '/infotrafic' ? 'active' : ''}`}
            onClick={handleClose}
          >
            <Link to="/infotrafic" className="menu-link">
              <div className="menu-content">
                <div className="menu-icon">
                  <TbMessage2Exclamation size={20} />
                </div>
                <div className="menu-text">Infos Trafic</div>
              </div>
            </Link>
          </div>

          {/* Changer de réseau */}
          <div
            className={`menu-item ${location.pathname === '/change-network' ? 'active' : ''}`}
            onClick={handleClose}
          >
            <Link to="/change-network" className="menu-link">
              <div className="menu-content">
                <div className="menu-icon">
                  <MdChangeCircle size={20} />
                </div>
                <div className="menu-text">Changer de réseau</div>
              </div>
            </Link>
          </div>

          {/* Calculer son itinéraire (désactivé) */}
          <div className="menu-item" style={{ marginTop: '30px' }}>
            <div className="menu-content disabled">
              <div className="menu-icon">
                <FaRoute size={20} />
              </div>
              <div className="menu-text">Calculer son itinéraire</div>
            </div>
            <div style={{ fontSize: '12px', marginTop: '5px', color: '#888' }}>
              Prochainement disponible
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* ============================================================
          OVERLAY TUTO (SI SHOWTUTORIAL EST TRUE)
         ============================================================ */}
      {showTutorial && (
        <div
          className="tutorial-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.7)',
            zIndex: 9999
          }}
          onClick={handleTutorialClose}
        >
          {/* Petit popover autour de l'icône */}
          <div
            style={{
              position: 'absolute',
              top: iconPos.y + iconSize.height + 10 + 'px',
              left: iconPos.x + iconSize.width / 2 - 150 + 'px',
              width: '300px',
              background: 'white',
              color: 'black',
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'center'
            }}
          >
            <p style={{ margin: '0', fontWeight: 'bold' }}>Changer de réseau</p>
            <p style={{ fontSize: '10px', margin: '5px 0' }}>
              Cliquez ici pour changer de réseau de bus
            </p>
            <div
              onClick={handleTutorialClose}
              style={{
                background: '#0a78a4',
                color: '#fff',
                padding: '5px 10px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                marginTop: '10px',
                display: 'inline-block'
              }}
            >
              OK
            </div>
          </div>

          {/* Flèche qui pointe vers l'icône */}
          <div
            style={{
              position: 'absolute',
              top: iconPos.y + iconSize.height - -5 + 'px',
              left: iconPos.x + iconSize.width / 2 - 5 + 'px',
              width: '10px',
              height: '10px',
              transform: 'rotate(45deg)',
              background: 'white'
            }}
          />
        </div>
      )}

      {/* ============================================================
          STYLES
         ============================================================ */}
      <style jsx>{`
        .navbar-custom.fixed-top {
          position: fixed;
          width: 100%;
          top: 0;
          z-index: 1030;
        }
        .menu-item {
          margin-bottom: 15px;
        }
        .menu-item.active .menu-content {
          background-color: #084d68;
        }
        .menu-link {
          text-decoration: none;
        }
        .menu-content {
          display: flex;
          align-items: center;
          background-color: #0a78a4;
          padding: 10px;
          border-radius: 8px;
          color: white;
          transition: background-color 0.3s;
        }
        .menu-content:hover {
          background-color: #084d68;
        }
        .menu-content.disabled {
          background-color: #ccc !important;
          color: #666 !important;
          cursor: not-allowed;
        }
        .menu-icon {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        .menu-text {
          font-size: 16px;
          font-weight: bold;
        }
        body {
          padding-top: ${isFixed ? '70px' : '0'};
        }
      `}</style>
    </>
  );
};

export default Header;
